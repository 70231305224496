Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";
exports.patchApiMethod = "PATCH";
exports.btnExampleTitle = "CLICK ME";
exports.map = "Map";
exports.deleteapiendpoint = "bx_block_appointment_management/appointments/update_status/";
exports.acceptrideendpoint = "bx_block_appointment_management/appointments/update_status/";
exports.completeError = "Ride can not be completed";
exports.cancelError = "Ride can not be cancelled";
exports.complete = "Ride completed sucessfully";
exports.cancel = "Ride cancelled sucessfully";
exports.ordersummaryapi= "bx_block_tasks/task_lists/order_summary/";
// Customizable Area End