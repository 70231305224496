Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.putApiMethod = "PUT";
exports.postAPIDriver="account_block/accounts";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.multipartApiContentType = "multipart/form-data";

exports.validationApiMethodType = "GET";
exports.sendDataApiEndPoint="account_block/accounts/update_driver_bank_account_details?id="
exports.mobileNumberApiEndPoint="/account_block/accounts/add_mobile_details?id="
exports.getVihicalaApiEndPoint="/bx_block_location/vehicles"
exports.vehicalAPIEndPoint="account_block/accounts/add_mobile_details?id="
exports.createYourEryde = 'Create your E-Ryde account';
exports.post = "POST";
exports.stepsOne = 'Step 1 of 3';
exports.stepsTwo = 'Step 2 of 3';
exports.stepsThree = 'Step 2 of 3';
exports.fname = 'First Name';
exports.lname = 'Last Name';
exports.email = 'Email address';
exports.iAgress = 'I agree with Privacy Policy and Terms and Conditions';
exports.continue = 'Continue';
exports.alreadyHavAccount = 'Already have an account?';
exports.yourSafe = 'Your safe and smooth Ride Awaits.';
exports.nonMedical = 'Non-medical transport';
exports.moNumber = 'Mobile number';
exports.moNumberInput = 'mobile number';
exports.requirement = 'Additional Requirements?';
exports.requirementWheel = ' e.g. wheelchair user, gurney';
exports.none = 'None';
exports.wheelchair = 'Wheelchair';
exports.Post = "POST";
exports.gurney = 'Gurney';
exports.anythingElse = 'Anything else you’d like us to know? ';
exports.iCanWalk = 'e.g. I can walk up and down stairs';
exports.login = ' Log In';
exports.allSet = "You're all set!";
exports.greatNews = "Great news! You're now ready to request rides with ease.";
exports.exploreride = 'Explore Rides';
exports.homeAdd = 'Home address';
exports.address1 = 'Address line 1';
exports.address2 = 'Address line 2';
exports.city = 'City';
exports.stateAdd = 'State';
exports.country = 'County';
exports.zipCode = 'Zip code';
exports.back = 'Back';
exports.signUp = 'Sign Up'; 
exports.oops = 'Oops! We couldn’t find that address.';
exports.double = 'Please double-check the spelling or try a different location.';
exports.retry = 'Retry';
exports.hasUpperCase = 'At least one capital letter';
exports.hasLowerCase = 'At least one lowercase letter';
exports.hasNumber = 'At least one number';
exports.hasSpecialChar = 'At least one special character';
exports.minimumChar = 'Minimum character length is 8 characters';
exports.updateRiderAccount = 'account_block/accounts/update_rider_account';
exports.putMethod = 'PUT';
exports.additionalRequirement = "Please select Additional Requirements";
exports.invalidEmail = "Invalid email";
exports.googleGetPlaceApi = "https://maps.googleapis.com/maps/api/place/autocomplete";
exports.googleGetPredictionApi = "https://maps.googleapis.com/maps/api/geocode/";
exports.Sucess1 = "We've received your application and everything looks";
exports.Sucess2 = "good so far. We'll be in touch soon to let you know the";
exports.Sucess3 = "next steps.";
exports.sucessAbove='Application Sent!';
exports.apiMethodTypeAddDetail = "POST";
exports.DriverFirsttext = "Earn on the Road, Drive with Freedom";
exports.BigTitle = "Welcome aboard!";
exports.SmallTitle1 = "Your account has been created sucessfully and";
exports.SmallTitle2 = "approved to start driving";
exports.BigTitle1Failure = "Oops! We found some issues with your";
exports.BigTitle2Failure = "documents";
exports.SmallTitle1Failure = "We need a bit more info. Check your email for details on";
exports.SmallTitle2Failure = "what to do next.";
exports.BigTitleReview = "Application Under Review";
exports.SmallTitle1Review = "Our team is currently reviewing your application.";
exports.SmallTitle2Review = "We'll let you know by email once it's finished.";
exports.BigTitle1Reject = "Application Needs Attention";
exports.SmallTitle1Reject = "We received your application, but there seems to be a";
exports.SmallTitle2Reject = "missing piece of information, No worries! We've sent";
exports.SmallTitle3Reject = "you an email with details on what you need to do next.";
exports.Enter = "Enter";
exports.State="State/Province";
exports.OK = "Contact Us";
exports.Key="AIzaSyAZxlbMgH-Nu_uL93AFRfcYPORePIURoTA"
exports.signUp = "Sign Up";
exports.step3EndPoint = "account_block/accounts/update_driver_vehicle_details?id=";
// Customizable Area End
