//Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Button,
  Typography,
  TextField,
  Modal,
  CircularProgress
} from '@mui/material';
import ForgotPasswordController, { configJSON } from "./ForgotPasswordController";
import { autorenew, popUp, logo } from "./assets";
//Customizable Area End

export default class ForgotPasswordWeb extends ForgotPasswordController {
  render() {
    // Customizable Area Start
    return (
      <Box>
        <StyledBox>
          <LeftHalf>
            <FormContainer>
              <Box>
                <HeaderOne data-testID={"passwordRecoveryID"}>Password Recovery</HeaderOne>
                <HeaderTwo>{configJSON.forgotPassword}</HeaderTwo>
                <HeaderThree>{configJSON.noWorries}</HeaderThree>
              </Box>

              {this.state.showApiError && (
                <CheckboxValidationContainer>
                  <Typography data-testID={"errorID"}>{this.state.apiErrorMessage}</Typography>
                </CheckboxValidationContainer>
              )}

              <InputWrapper>
                <CustomLabel>{configJSON.emailAddress}</CustomLabel>
                <TextFieldCustom
                  data-testID={"emailChangeID"}
                  variant="outlined"
                  fullWidth
                  placeholder="Type here your email address..."
                  InputLabelProps={{ shrink: true }}
                  name="emailValue"
                  value={this.state.emailValue}
                  onChange={this.handleChangeInput}
                />
              </InputWrapper>

              <ButtonContainer>
                <ButtonOne
                  data-testID={"submitId"}
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.handleSendEmail}
                >
                  {this.state.btnLoader ? <CircularProgress /> : configJSON.sendMail}
                </ButtonOne>
              </ButtonContainer>
              <BottomText>
                <TypographyBackTo>
                  {configJSON.back}
                  <ButtonBackto onClick={this.handleNavigateToLogin}>Log In</ButtonBackto>
                </TypographyBackTo>
              </BottomText>
              <RecoveryWrapper>
                <RecoveryImage src={autorenew.default} />
                <RecoveryText>
                  {configJSON.linkSend}
                </RecoveryText>
              </RecoveryWrapper>
            </FormContainer>
          </LeftHalf>

          <RightHalf>
          <MainImgage src={logo.default} alt="right-main" />

          <TextBox>
            <TypographyImgTextOne>{configJSON.yourSafeText}</TypographyImgTextOne>
            <TypographyImgTextTwo>{configJSON.nonMedical}</TypographyImgTextTwo>
          </TextBox>
        </RightHalf>

        </StyledBox>
        <Modal
          open={this.state.openResetPassModal}
          onClose={this.handleCloseForResetPass}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testID={"closeModalId"}
        >
          <ModalContainer boxShadow={24}>
            <TypographyPassRecovery data-testID={"passwordID"}>
              Password Recovery
            </TypographyPassRecovery>

            <StyledImageUpload src={popUp.default} alt="image" />

            <TypographyForEmail>
              {configJSON.checkYourEmail}
            </TypographyForEmail>

            <TypographyForResetLinkOne>
              {configJSON.checkResetLinkOne}
            </TypographyForResetLinkOne>

            <TypographyForResetLinkTwo>
              {this.state.emailValue}
            </TypographyForResetLinkTwo>

            <ButtonForResend onClick={this.handleSendEmail}>{configJSON.resendEmail}</ButtonForResend>

            <TypographyBackTo>
              {configJSON.back}
              <ButtonBackto data-testID={"signInId"} onClick={this.handleNavigateToLogin}>{configJSON.signin}</ButtonBackto>
            </TypographyBackTo>
          </ModalContainer>
        </Modal>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledBox = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  '@media (max-width: 960px)': {
    flexDirection: "column",
    height: "auto",
  }
});

const LeftHalf = styled("div")({
  width: "50%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ffffff",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
});

const FormContainer = styled("div")({
  width: "500px",
  height: "100%",
  overflow: "scroll",
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: "none",
  "@media (max-width: 960px)": {
    width: "80%",
    marginTop: "20px"
  }
});

const TextFieldCustom = styled(TextField)({
  width: '100%',
  height: "56px",
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});

const HeaderOne = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "30px",
  color: "#1D6FB8",
  marginTop: "80px",
  "@media (max-width: 960px)": {
    fontSize: "20px",
    marginTop: "30px",
  }
}));

const HeaderTwo = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "20px",
  color: "#0F172A",
  marginTop: "30px",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    marginTop: "25px",
  }
}));

const HeaderThree = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: "16px",
  color: "#334155",
  margin: "10px 0px 20px 0px",
  [theme.breakpoints.down("md")]: {
    fontSize: "15px",
    margin: "5px 0px 10px 0px",
  }
}));

const CheckboxValidationContainer = styled(Box)({
  width:"100%",
  maxWidth: "440px",
  height: "50px",
  margin:"10px 0px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
  display: "flex",
  textAlign:"left",
  textTransform:"none",
  gap:"8px",
  padding: "12px 16px 12px 16px",
  borderLeft: "4px solid #DC2626",
  borderRadius: 4,
  fontSize: "14px",
  fontWeight:400,
  '&:hover':{
    backgroundColor: "#FEE2E2",
  }
});

const InputWrapper = styled(Box)({
  width: "100%",
  maxWidth:"440px",
  height:"56px"
});

const CustomLabel = styled(Typography)({
  backgroundColor: "white",
  fontSize: "14px",
  color: "#334155",
  fontWeight: 700,
  marginBottom: "5px"
});

const ButtonContainer = styled("div")({
  marginTop: "40%",
  width: '100%',
  height: '56px',
  maxWidth:"440px",
  marginBottom: "10px",
  "& .css-1fu7jd5-MuiButtonBase-root-MuiButton-root": {
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    background: "#1D6FB8",
    textTransform: "none"
  }
});

const ButtonOne = styled(Button)({
  height: '50px',
  color: '#ffffff',
  backgroundColor: '#1D6FB8',
  cursor: "pointer",
  textTransform: 'none',
  borderRadius: '10px',
  fontSize: "20px",
});

const BottomText = styled("div")({
  textAlign: "center",
  width: '100%',
  maxWidth:"440px",
  fontWeight: 400,
  color: "#000000",
});

const RecoveryWrapper = styled(Typography)({
  display: "flex",
  width: '100%',
  maxWidth:"440px",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px 0px"
});

const RecoveryText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  fontWeight: 400,
  fontSize: "14px",
  color: "#1D6FB8",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  }
}));

const RecoveryImage = styled("img")(({ theme }) => ({
  width: "20px",
  height: "20px",
  marginRight: "10px",
  [theme.breakpoints.down("md")]: {
    width: "15px",
    height: "15px",
    marginRight: "4px",
  }
}));

const ModalContainer = styled(Box)({
  width: '500px',
  height: '500px',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: '#ffffff',
  padding: '30px 0px',
  borderRadius: "20px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const TypographyPassRecovery = styled(Typography)({
  fontWeight: 700,
  fontSize: '24px',
  width: '250px',
  height: '32px',
});

const TypographyForEmail = styled(Typography)({
  fontWeight: 700,
  fontSize: '20px',
  width: '218px',
  height: '28px',
  marginTop: '15px',
});

const TypographyForResetLinkOne = styled(Typography)({
  fontWeight: 400,
  fontSize: '18px',
  width: '450px',
  height: '28px',
  margin: '5px 0px',
});

const TypographyForResetLinkTwo = styled(Typography)({
  fontWeight: 400,
  fontSize: '18px',
  width: '450px',
  height: '28px',
  marginTop: '5px',
});

const StyledImageUpload = styled('img')({
  width: '187px',
  height: '195px',
});

const ButtonForResend = styled(Button)({
  color: '#1D6FB8',
  fontWeight: 700,
  fontSize: '20px',
  marginTop: '15px',
  textTransform: "none"
});

const TypographyBackTo = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  fontWeight: 400
});

const ButtonBackto = styled(Button)({
  color: '#1D6FB8',
  fontSize: "16px",
  fontWeight: 700,
  textTransform: "none"
});

const RightHalf = styled(Box)({
  width: "50%",
  height: "100%",
  textAlign: 'center',
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    width: "100%",
    height: "100%",
  }
})
const MainImgage = styled('img')({
  width: '100%',
  height: '85%',
  "@media (max-width: 960px)": {
    width: '100%',
  }
});

const TextBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center"
});

const TypographyImgTextOne = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
});

const TypographyImgTextTwo = styled(Typography)({
  color: '#1D6FB8',
  marginTop: '14px',
  fontWeight: 400,
  fontSize: '20px',
  "@media (max-width: 960px)": {
    fontSize: '15px',
  }
})

// Customizable Area End