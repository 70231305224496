// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface ApiCall {
  contentType: string;
  method: string;
  endPoint: string;
  body?: {}
}

export interface ValidResponse {
  message: string;
}

export interface InValidResponse { error: string }

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  showAddressForm: boolean;
  address: string;
  contactName: string;
  phoneNumber: string;
  errors: {
    address?: string;
    contactName?: string;
    phoneNumber?: string;
};
isToastOpen:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FavLocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPasswordConfirmApiCallId: string = "";
  postFavLocation:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      showAddressForm: false,
      address: '',
      contactName: '',
      phoneNumber: '',
      errors: {
        address: "",
        contactName: "",
        phoneNumber: "",
    },
    isToastOpen:false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            runEngine.debugLog("API Message Recived", message);
      
            const responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (
              apiRequestCallId === this.postFavLocation
            ) {
              if (responseJson && responseJson.meta.message==="Favorite location added successfully") {
                this.setState({isToastOpen:true})
              }
            }
          }
    // Customizable Area End
  }

  // Customizable Area Start
  handleToastClose = () => {
    this.setState({ isToastOpen: false })
  }
  handleAddAddress = () => {
    this.setState({ showAddressForm: true });
  };
  postData=(address: string, contactName?: string, phoneNumber?: string)=>{
    const token = localStorage.getItem("token");
    const header = {
      token: token,
      "Content-Type": "application/json",
  };
  let body ={
    "data": {
      "attributes": {
        "address":address,
        "contact_name": contactName,
        "phone_number":  phoneNumber
      }
    }
  }
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.postFavLocation = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postFavLocationendPoint}`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
)
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangeInput = (event: any) => {
    const { name, value } = event.target;

    this.setState((prevState) => ({
        ...prevState, // Preserve the existing state
        [name]: value, // Update the field dynamically
        errors: {
            ...prevState.errors, // Preserve existing errors
            ...(name === "address" && value.trim() === "" ? { address: "Address is required" } : { address: "" }),
            ...(name === "contactName" && value && (value.length < 3 || value.length > 50)
                ? { contactName: "Name must be between 3 and 50 characters" }
                : { contactName: "" }),
            ...(name === "phoneNumber" && value && !/^\d{10}$/.test(value)
                ? { phoneNumber: "Phone number must be exactly 10 digits" }
                : { phoneNumber: "" }),
        },
    }));
};
handleSave = () => {
  const { address, contactName, phoneNumber } = this.state;
  let errors:any = {};

  if (!address.trim()) {
      errors.address = "Address is required";
  }

  if (contactName && (contactName.length < 3 || contactName.length > 50)) {
      errors.contactName = "Name must be between 3 and 50 characters";
  }

  if (phoneNumber && !/^\d{10}$/.test(phoneNumber)) {
      errors.phoneNumber = "Phone number must be exactly 10 digits";
  }
  if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return; // Stop execution if errors exist
  }

  this.postData(address, contactName, phoneNumber);
};

  // Customizable Area End
}
