import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
interface ErrorResponse {
  errors: Array<{
    failed_login: string;
    account_id?: any;
    email?:any;
  }>;  
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  loginError: string;
  showPassword: boolean;
  emailError: boolean;
  passwordError:boolean;
  isToastOpen:boolean;
  roleID:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAccountApiCallId: string = "";
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      loginError: "",
      showPassword: false,
      emailError: false,
      passwordError:false,
      isToastOpen:false,
      roleID:'',
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;

    this.navigateToRiderSignUP=this.navigateToRiderSignUP.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

    
    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        password:'',
        passwordError:false,
      });
      const password = event.target.value;
      
      // Regex for validating password: at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character
      const passwordError = !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
      
      this.setState({
        password,
        passwordError,
      });
    };
  
    handlePasswordVisibility = () => {
      this.setState((prevState) => ({
        showPassword: !prevState.showPassword,
      }));
    };

    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const email = event.target.value;
      const emailError = !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
      this.setState({
          email,
          emailError,
      });
  };

  
  handleForgot = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPassword");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
}
  

  handleSubmit = async () => {
    const {  email, password, emailError, passwordError } = this.state;
  if ( !email || !password) {
    this.setState({
      emailError: !email || emailError, // true if email is invalid or empty
      passwordError: !password || passwordError, // true if password is empty or invalid
    });
    return; // Stop the submission
  }

    if (!emailError && !passwordError) {
    const header = {
      "Content-Type": "application/JSON"
    };
    const data = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_login/logins"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  handleRedirect = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplates");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  checkAfterToken=(responseJson: any)=>{
    setStorageData("token", responseJson.meta.token);
    setStorageData("role_id", responseJson.meta.role_id);
    setStorageData("role", responseJson.meta.role_id);
    setStorageData("userId", responseJson.meta.id);
    this.setState({roleID:responseJson.meta.role_id})
    this.openToast()
   
  }
  handleToastClose = () => {
    this.setState({ isToastOpen: false })
    if (this.state.roleID==='driver'){
      this.navigateToDriverSucess() 
     }else if(this.state.roleID==='rider'){
      this.handleRedirect()
     }
  }
  openToast = () => {
    this.setState({ isToastOpen: true })
  }
  handleLoginConditions(responseJson: any) {
    // Check if responseJson and errors exist and errors array is not empty
    if (responseJson && responseJson.errors && responseJson.errors.length > 0) {
      const failedLoginMessage = responseJson.errors[0].failed_login.message;
  
      if (failedLoginMessage === "Your signup request is rejected") {
        localStorage.setItem(
          "userId",
          responseJson.errors[0].failed_login.account_id
        );
        localStorage.setItem(
          "role_id",
          "driver"
        );  
        this.navigateToRejectionScreen();
      } else if (failedLoginMessage === "Your account is blocked, please contact admin") {
        localStorage.setItem(
          "userId",
          responseJson.errors[0].failed_login.account_id
        );
        localStorage.setItem(
          "role_id",
          "driver"
        );  
        this.navigateToBlockScreen();
      } else if (failedLoginMessage === "Your signup request is under review") {
        localStorage.setItem(
          "userId",
          responseJson.errors[0].failed_login.account_id
        );
        localStorage.setItem(
          "role_id",
          "driver"
        );  
        this.navigateToReviewScreen();
      } else if (failedLoginMessage === "No account registered with this mail") {
        this.setState({ loginError: 'No account registered with this email' });
      }  else if (failedLoginMessage === "Password is incorrect") {
        this.setState({ loginError: 'Password is incorrect' });
      } else if (failedLoginMessage === "Your driver details are incomplete. Please complete your profile") {
        localStorage.setItem(
          "userId",
          responseJson.errors[0].failed_login.account_id
        );
        localStorage.setItem(
          "navigationStep",
          responseJson.errors[0].failed_login.signup_step
        );
        localStorage.setItem(
          "role_id",
          "driver"
        );   
        this.navigateToSignUpScreen();
      } else if (failedLoginMessage=== "Please complete OTP verification before logging in.") {
        localStorage.setItem(
          "email",
          responseJson.errors[0].failed_login.email
        );
        localStorage.setItem(
          "role_id",
          "driver"
        );   
        this.navigateToAuthenticationScreen();
      }
    } 
  }
  navigateToAuthenticationScreen=()=>{
    console.log("@@@ ASFADFKNADFKAD")

    const msg: Message = new Message(
      getName(MessageEnum.SignUpDriverNextDataPassMessage)
    );
    msg.addData(getName(MessageEnum.SignUpDriverNextData), {
      step: 3,
      initialValues3: {
        AccNum: "",
        AcchName: "",
        ConAccNum: "",
        RNum: ""
      },
    });
    this.send(msg);

    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "TwoFactorAuthentication");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToSignUpScreen=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriverNext");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToReviewScreen(){
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriverStep3");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToBlockScreen(){
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpDriverStep4");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToRejectionScreen(){
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "DriverFailureScreen");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToDriverSucess(){
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "DriverSucessScreen");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToRiderSignUP(){
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "SignUpRider");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

     if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
        if (apiRequestCallId === this.createAccountApiCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.checkAfterToken(responseJson) 
            localStorage.setItem("FirstName", responseJson.meta.first_name	)
          } else if(responseJson.errors) {
            this.handleLoginConditions(responseJson)
            //Check Error Response
          }
        }
      
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    // Merge Engine - Navigation - btnEmailLogIn - Start
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
    // Merge Engine - Navigation - btnEmailLogIn - End
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
