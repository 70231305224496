import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import creditCardType from "credit-card-type";
import * as Yup from 'yup'
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  redirectedit?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedSection: string;
  isSidebarOpen: boolean;
  openmodal: boolean;
  usertype: string
  pagetoshow: string
  cardnumber: string
  prevpass: string
  expirrydate: string
  cardHolderName: string
  oldpassunmatch: boolean
  cardList: any
  isToastOpen: boolean
  isValid: any
  userdetails: any
  cardHolderNameError: boolean
  cardType: any
  paypalidError: boolean
  expirrydateError: boolean
  succesMessage: string
  prevpassvisible: boolean
  newpassvisibile: boolean
  paypalid: string
  isInputFocused:boolean
  isCardinputfocused:boolean
  confirmpassvisibile: boolean
  selectedState: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddPaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addCardApiCallId: any
  getCardlistApiCallId: any
  changePassowrdApiCallId: string
  userprofiledetailsApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.addCardApiCallId = ""
    this.getCardlistApiCallId = ""
    this.userprofiledetailsApiCallId = ""
    this.changePassowrdApiCallId= ""
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedSection: 'Settings',
      isSidebarOpen: true,
      openmodal: false,
      usertype: "",
      cardnumber: "",
      pagetoshow: "",
      prevpass: "",
      expirrydate: "",
      cardList: [],
      oldpassunmatch: false,
      cardHolderName: "",
      isToastOpen: false,
      isValid: null,
      userdetails: {},
      cardHolderNameError: false,
      cardType: '',
      paypalidError: false,
      expirrydateError: false,
      paypalid: "",
      prevpassvisible: false,
      succesMessage: "",
      confirmpassvisibile: false,
      newpassvisibile: false,
      isCardinputfocused:false,
      isInputFocused:false,
      selectedState: " "
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      runEngine.debugLog("API Message Recived", message);

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userlogged = localStorage.getItem("role_id");
    if (userlogged) {
      this.setState({ usertype: userlogged })
    }
    // Customizable Area End
  }

  validationSchema = Yup.object().shape({

    paypalid: Yup.string()
      .required('PayPal Id / Apple pay is required'),
    expirrydate: Yup.string()
      .required("Expiry date is required")
      .matches(/^\d{2}\/\d{2}$/, "Expiry date must be in MM/YY format")
      .test("is-valid-month", "Month must be between 01 and 12", (value) => this.validateMonth(value))
      .test("is-future-date", "Expiry date must be in the future", (value) => this.validate(value)),
    cardHolderName: Yup.string()
      .required('Card holder name is required')
      .min(4, 'Card holder name must be at least 4 digits')
      .max(15, 'Card holder name cannot be more than 30 digits')
      .matches(/^[A-Za-z\s]+$/, 'Card holder name must contain only letters'),

  });

  validateMonth(value: any) {
    if (value) {
      const month = parseInt(value.split("/")[0], 10);
      return month >= 1 && month <= 12;
    }
    return true;
  }

  validate(value: any) {
    if (value) {
      const [monthStr, yearStr] = value.split("/");
      const month = parseInt(monthStr, 10);
      const inputYear = 2000 + parseInt(yearStr, 10);

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      return (
        inputYear > currentYear ||
        (inputYear === currentYear && month >= currentMonth)
      );
    }
    return true;
    // }
  }

  handleCardNumberChange = (e: any) => {
    const input = e.target.value.replace(/\D/g, '');
    this.setState({ cardnumber: input })
    const detectedTypes = creditCardType(input);
    if (detectedTypes.length > 0) {
      const type = detectedTypes[0];
      this.setState({ cardType: type.niceType })
      const isLengthValid = type.lengths.includes(input.length);
      const isValidLuhn = isLengthValid && this.validateLuhn(input);
      this.setState({ isValid: isValidLuhn })

    } else {
      this.setState({ cardType: "unknown" })
      this.setState({ isValid: false })
    }
  };
  validateLuhn = (number: any) => {
    let sum = 0;
    let shouldDouble = false;

    for (let i = number.length - 1; i >= 0; i--) {
      let digit = parseInt(number[i], 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  };

  handleNavigation = (route: string) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }


  handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let formattedValue = value.replace(/\D/g, "");

    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: name == "expirrydate" ? formattedValue : value,
      [`${name}Error`]: false,
    }));

    try {
      await this.validationSchema.validateAt(name, { ...this.state, [name]: name == "expirrydate" ? formattedValue : value });
      this.setState((prevState) => ({
        ...prevState,
        [`${name}Error`]: "",
      }));
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        this.setState((prevState) => ({
          ...prevState,
          [`${name}Error`]: error.message,
        }));
      }
    }

  };


  // Customizable Area End
}
