import React, { Component } from "react";
import {ambulatory, gurney, Logo, tickSign, wheel} from '../../blocks/email-account-registration/src/assets'
import { Box, Button, TextField, Checkbox, styled, Typography, LinearProgress, Dialog,DialogContent, Select, MenuItem, InputAdornment, FormControl, FormLabel} from "@mui/material";
import { fileUpload,dropdown, image1, image2, image3, check} from "../../blocks/email-account-registration/src/assets";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CSSProperties } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Additional } from "../../blocks/email-account-registration/src/SignUpRiderController.web";
import PhoneInput from "react-phone-input-2";
import { Opacity } from "@mui/icons-material";
interface MyFormProps {
  initialValues: any; // Replace 'any' with the type of your initial values
  Submit1: (values: any) => void; // Replace 'any' with the type of your form values
  back:() => void;
  mobNoError:boolean;
}

interface MyFormState {
  ModalOpen: boolean;
  optionsList1: string[];
  isFocusmobNo:boolean;
  isFocusDLicense:boolean;
  isFocusInsuranceNo:boolean;
  isFocusInsuranceNoDoc:boolean;
  isFocusDMVDoc:boolean;
  isFocusDLicensePic:boolean;
  fileInsuranceNoDoc:string;
  fileDMVDoc:string;
  fileDLicensePic:string;
  isFocusAddreq:boolean;
  fieldClick: boolean;
  vehicleOption: Additional[];
  selectedVehicle: string;
  countryCode: string;
}


const LogoContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
  });
  
  const LogoTextBottom1 = styled("div")({
    marginTop: "16px",
    fontWeight: 400,
    fontSize: "18px",
    color: "white",
  });
  
  const RoadText = styled(Typography)({
    color: "white",
    fontWeight: 400,
    fontSize: "30px",
    marginTop: "40px",
  });
  
  const ProgressBarContainer = styled("div")({
    width: "100%",
    margin: "20px 0",
  });
  
  const Heading = styled(Typography)({
    fontWeight: 400,
    fontSize: "30px",
    color: "#1D6FB8",
    width: "100%",
    marginBottom: "16px",
  });
  
  const CustomLinearProgress = styled(LinearProgress)({
    height: "10px",
    borderRadius: "5px",
    backgroundColor: "#E2E8F0",
    '& .MuiLinearProgress-bar': {
        backgroundColor: "#4caf50",
    },
  });


const MyFormLabel = styled(FormLabel)({
  color: 'rgba(15, 23, 42, 1)',
  fontWeight: 700,
  fontFamily: "'Inter', sans-serif",
  letterSpacing: '0.5px',
  fontSize: '14px',
  marginBottom: '5px',
});

const AddWrapper = styled('div')({
  zIndex: 40,
  marginTop: "85px",
  position: "absolute",
  width: "100%",
  maxWidth: "440px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  border: "1px solid #F5F5F5", height: "216px",
  backgroundColor: "#FFFFFF", borderRadius: "8px"
});

const CustomPhoneInput = styled(PhoneInput)({
  "&.react-tel-input .flag-dropdown": {
    border: "none",
    backgroundColor: "white"
  },
  "&.react-tel-input": {
    width: "40px"
  },
  "&.react-tel-input .form-control": {
    display: "none"
  },
  "&.react-tel-input .selected-flag .arrow": {
    left: "24px",
    height: "4px",
    width: "7px"
  }
});

const CustomPhoneNumber = styled('div')({
  height: "56px",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  padding: "10px 8px"
});

const CustomeMobileNumber = styled('input')({
  marginLeft: "10px",
  border: "none",
  fontSize: "16px",
  fontWeight: 400
  , outline: "none",
  height: "100%",
  "&:focus": {
    border: "none",
    outline: "none"
  },
  '&::placeholder': {
    color: '#A6B0BF',
  },
});

const CountryCode = styled("span")(({ theme }) => ({
  marginLeft: "10px",
  fontSize: "16px",
  fontWeight: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginLeft: '45px',
  },
}));
   
  const validFileTypes = [
    'application/pdf', // PDF files
    'image/jpeg',      // JPEG images
    'image/jpg',       // JPG images
    'image/png'        // PNG images
  ];
  const validationschema = Yup.object().shape({
    mobNo: Yup.string()
      .required('Mobile number is required')
      .matches(/^\d{10,12}$/, 'Mobile number must be 10 to 12 digits'),
    
      DLicense: Yup.string()
      .required('Driving licence number is required')
      .matches(/^\d+$/, 'Driving licence number must contain only digits') // Ensure only digits
      .min(4, 'Driving licence number must be at least 4 digits')
      .max(15, 'Driving licence number cannot be more than 15 digits'),
      InsuranceNo: Yup.string()
      .required('Insurance number is required')
      .matches(/^\d+$/, 'Insurance number must contain only digits') // Ensure only digits
      .min(4, 'Insurance number must be at least 4 digits')
      .max(15, 'Insurance number cannot be more than 15 digits'),
      InsuranceNoDoc: Yup.array()
      .required('Insurance document is required')
      .min(1, 'At least one insurance document is required')
      .max(10, 'You can upload up to 10 insurance documents only')
      .test('fileType', 'Only PDF or JPEG, JPG, PNG images are allowed', (value) => {
        if (!value || value.length === 0) return false;
        return value.every((file: File) => validFileTypes.includes(file.type));
      }),
  
    DMVDoc: Yup.array()
      .required('DMV document is required')
      .min(1, 'At least one DMV document is required')
      .max(10, 'You can upload up to 10 DMV documents only')
      .test('fileType', 'Only PDF or JPEG, JPG, PNG images are allowed', (value) => {
        if (!value || value.length === 0) return false;
        return value.every((file: File) => validFileTypes.includes(file.type));
      }),
  
    DLicensePic: Yup.array()
      .required('Driving licence picture is required')
      .min(1, 'At least one driving licence picture is required')
      .max(10, 'You can upload up to 10 driving licence pictures only')
      .test('fileType', 'Only PDF or JPEG, JPG, PNG images are allowed', (value) => {
        if (!value || value.length === 0) return false;
        return value.every((file: File) => validFileTypes.includes(file.type));
      }),
      
  });
  
  const vehicleOptions = [
    { id: "1", label: "Ambulatory", value: "ambulatory", icon: image1.default },
    { id: "2", label: "Wheelchair", value: "wheelchair", icon: image2.default },
    { id: "3", label: "Gurney", value: "gurney", icon: image3.default },
  ];
 
  const webStyle = { 
    InnerFirst:{color:'#1D6FB8',fontSize:'20px',fontWeight:400,marginBottom:'15px'},
    ErrorMessage:{ color:'#DC2626', fontSize: '12px',fontWeight:'400', marginTop: '5px',marginBottom:'5px' },
    FieldHeader2:{
     fontSize: "14px",
     fontWeight: 700,
     paddingBottom:'5px',
    },
    ButtonStyle2:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    ButtonStyle22:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
    marginBottomm:{ marginBottom: "15px" },
    InputFIeldStyle:{width:'100%',},
    InputFIeldStyle2:{width:'100%',height:'56px',borderRadius:'8px',fontSize:'16px'},
    FieldHeader:{
     fontSize: "14px",
     fontWeight: 700,
     paddingBottom:'5px',
   MarginTop:'5px'
    },
    ButtonStyle3:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    ButtonStyle23:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
    marginBottomm3:{ marginBottom: "15px" },
    InputFIeldStyle3:{width:'100%',height:'44px',borderRadius:'8px'},
    
    ButtonStyle:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    marginBottomm4:{ marginBottom: "15px" },
    InputFIeldStyle4:{width:'100%',height:'44px',borderRadius:'8px'},
    dotStyle:{
      paddingLeft: '10px',
      fontSize: '16px',
      fontWeight: '400',
      whiteSpace: 'nowrap',      
      overflow: 'hidden',       
      textOverflow: 'ellipsis',
      width: '85%',             
    },
    dotStylePlaceholder:{
      paddingLeft: '10px',
      fontSize: '16px',
      fontWeight: '400',
      whiteSpace: 'nowrap',      
      overflow: 'hidden',       
      textOverflow: 'ellipsis',
      width: '85%',  
      color: "rgba(148, 163, 184, 1)"           
    }
   };
   const TextFieldCustom = styled(TextField)({
    width: '100%',
    height: "40px",
    marginBottom: "20px",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
  });
  const ErrorBox = styled("span")({
    display: "block",
    width: "100%",
    padding: "12px",
    backgroundColor: "#FEE2E2",
    color: "black",
    border: "1px solid #f5c6cb",
    borderRadius: "8px",
    marginBottom: "16px",
    textAlign: "initial",
    boxSizing: "border-box",
    fontSize: "12px",
    fontWeight: 100,
  });
  
  const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: selected ? "#E8F5E9" : "inherit",
  }));

  const IconWrapper = styled("span")({
    marginLeft: "auto",
    color: "green",
  });
class MyFormComponent extends Component<MyFormProps, MyFormState> {
  state: MyFormState = {
    isFocusmobNo: false,
    isFocusInsuranceNo: false,
    ModalOpen: false,
      isFocusDLicense:false,
      isFocusInsuranceNoDoc:false,
      isFocusDMVDoc:false,
      isFocusDLicensePic:false,
      isFocusAddreq:false,
      optionsList1:['None', 'Wheelchair', 'Gurney'],
      fileInsuranceNoDoc:'',
      fileDMVDoc:'',
      fileDLicensePic:'',
      fieldClick: false,
    vehicleOption: [{
      id: 1,
      name: "Ambulatory",
      img: ambulatory,
      isSelected: false,
      tickSign: tickSign
    },
    {
      id: 2, 
      name: "Wheelchair",
      img: wheel,
      isSelected: false,
      tickSign: tickSign
    },
    {
      id: 3, 
      name: "Gurney",
      img: gurney,
      isSelected: false,
      tickSign: tickSign
    }],
    selectedVehicle: "",
    countryCode: "44"
  };
  constructor(props:any) {
    super(props);
  }
  handleDialogClose2=()=>{
    this.setState({ModalOpen:false})
      }
    handleDialogOpen2=()=>{
        this.setState({ModalOpen:true})
          }
          renderErrorMessage = ({ errors, touched, name, style }:any) => {
            if (errors[name] && touched[name]) {
              return (
                <div style={style}>
                  <ErrorMessage name={name} />
                </div>
              );
            }
            return null;
          };

  onFieldClicked = () => {
    this.setState({ fieldClick: !this.state.fieldClick });
  };


    
  render() {
    const { initialValues, Submit1,mobNoError} = this.props;
  
    return (
      <div className="outer-container">
      <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        flexDirection: { xs: "column", md: "row" }, // column on small screens, row on larger screens
      }}
      >
        <div className="left-half">

          <Formik initialValues={initialValues} validationSchema={validationschema} onSubmit={(values, { resetForm }) => {
            Submit1(values)
            // resetForm({ values });
          }} enableReinitialize={true} validateOnChange={true}  // Enable validation on change
            validateOnBlur={true} >
            {({ errors, touched, setFieldValue, values, resetForm, setFieldTouched, validateForm }) => {

                const handleSelectVehicle = (id: number, name: string) => {
                  setFieldValue("Addreq", id)
                  this.setState({ selectedVehicle: name, fieldClick: !this.state.fieldClick });

                  this.setState((prevState) => {
                    const updatedField = prevState.vehicleOption.map((data) => {
                      if (data.id === id) {
                        data.isSelected = true;
                      } else {
                        data.isSelected = false;
                      }
                      return data;
                    });

                    return { vehicleOption: updatedField };
                  });
                };

              const countryCodeData = (value:string) => {
                  this.setState({countryCode:value})
                  setFieldValue("countryCode", value); 
              };

              return (<Form translate={undefined} className="custom-style">
                <Heading data-testid="signup-heading">
                  Create your E-Ryde account
                </Heading>

                <ProgressBarContainer>
                  <Typography variant="body2" style={{ fontWeight: 400, fontSize: "18px", color: "rgba(29, 111, 184, 1)", marginBottom: "8px" }} data-testid="progress-step">
                    Step 2 of 4
                  </Typography>
                  <CustomLinearProgress variant="determinate" value={50} data-testid="progress-bar" />
                </ProgressBarContainer>
                <br />
                {mobNoError ? (
                  <ErrorBox>Mobile number is already taken</ErrorBox>
                ) : (
                 null
                )}
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Mobile number
                  </Typography>
                  <div style={{ position: 'relative', width: '100%' }}>
                    <CustomPhoneNumber>
                      <CustomPhoneInput
                        country={"gb"}
                        onChange={countryCodeData}
                        placeholder="Type your phone number..."
                        inputClass="!w-full !h-12 !text-lg !rounded-lg !border-gray-300 !pl-14"
                        containerClass="!w-full"
                        buttonClass="!bg-transparent !border-none"
                        dropdownClass="!rounded-lg !border-gray-300 !shadow-md"
                      />
                      {
                        values.mobNo &&
                        <CountryCode>{`(+${this.state.countryCode})`}</CountryCode>
                      }
                      <CustomeMobileNumber 
                      onChange={(e: { target: { value: string; }; }) => {
                        setFieldValue("mobNo", e.target.value); 
                        initialValues.mobNo = e.target.value
                      }} placeholder="Type here your mobile number..." value={values.mobNo} type="tel" id="mobile" name={"mobileNumber"} max="10" />
                    </CustomPhoneNumber>
                  </div>


                  {this.renderErrorMessage({
                    errors,
                    touched,
                    name: 'mobNo',
                    style: webStyle.ErrorMessage
                  })}
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Driving licence number 
                  </Typography>
                  <input
                    id="DLicense"
                    name="DLicense"
                    data-test-id='DLicense'
                    value={values.DLicense}
                    // className="placeholder-styled"
                    placeholder="Type here you Driving licence number..."
                    type="text"
                    onChange={(e) => {
                      setFieldValue("DLicense", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusmobNo ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusmobNo: true })}
                    onBlur={() => this.setState({ isFocusmobNo: true })}
                    style={{
                      ...webStyle.InputFIeldStyle2,
                      border: errors.DLicense && touched.DLicense ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {this.renderErrorMessage({
                    errors,
                    touched,
                    name: 'DLicense',
                    style: webStyle.ErrorMessage
                  })}
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Auto Insurance policy number
                  </Typography>

                  <input
                    id="InsuranceNo"
                    name="InsuranceNo"
                    data-test-id='InsuranceNo'
                    value={values.InsuranceNo}
                    // className="placeholder-styled"
                    placeholder="Type here you Auto Insurance policy number..."
                    type="text"
                    onChange={(e) => {
                      setFieldValue("InsuranceNo", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusmobNo ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusInsuranceNo: true })}
                    onBlur={() => this.setState({ isFocusInsuranceNo: true })}
                    style={{
                      ...webStyle.InputFIeldStyle2,
                      border: errors.InsuranceNo && touched.InsuranceNo ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {this.renderErrorMessage({
                    errors,
                    touched,
                    name: 'InsuranceNo',
                    style: webStyle.ErrorMessage
                  })}

                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Auto insurance policy document upload
                  </Typography>
                  <Field name="InsuranceNoDoc">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-InsuranceNoDoc"
                          data-test-id='InsuranceNoDoc'
                          type="file"
                          multiple
                          style={{ display: 'none' }}

                          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                           
                            const files = event.target.files ? Array.from(event.target.files) : [];
                            setFieldValue("InsuranceNoDoc", files); // Set multiple files
                            setFieldTouched("InsuranceNoDoc", true);
                            await validateForm();
                            if (files.length > 0) {
                              setFieldValue("InsuranceFIleName", files.map(file => file.name).join(", "));
                            }
                          }}
                          onBlur={() => {
                            // Manually trigger validation when the input loses focus
                            setFieldValue('InsuranceNoDoc', null);
                          }}
                        />
                        <label htmlFor="file-upload-InsuranceNoDoc">
                          <div
                            style={{
                              border: errors.InsuranceNoDoc && touched.InsuranceNoDoc ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style"
                          >
                            {values.InsuranceFIleName ? (
                              <Typography
                                style={webStyle.dotStyle}
                              >
                                {values.InsuranceFIleName}
                              </Typography>
                            ) : 
                            <Typography style={webStyle.dotStylePlaceholder}>
                            Upload Document
                          </Typography>
                          }
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                              className="button-icon"
                            />
                          </div>
                        </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="InsuranceNoDoc" component="div" />
                  </div>

                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    DMV document upload
                  </Typography>
                  <Field name="DMVDoc">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-DMVDoc"
                          data-test-id="DMVDoc"
                          type="file"
                          multiple // Allow multiple files
                          style={{ display: 'none' }}
                          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                            const files = event.target.files ? Array.from(event.target.files) : [];
                            setFieldValue("DMVDoc", files);  // Store array of files
                            await validateForm();

                            // Set file names for display
                            if (files.length) {
                              const fileNames = files.map(file => file.name).join(', ');
                              setFieldValue("DMVFileName", fileNames);
                            }
                          }}
                        />
                        <label htmlFor="file-upload-DMVDoc">
                          <div
                            style={{
                              border: errors.DMVDoc && touched.DMVDoc ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style"
                          >
                            {values.DMVFileName ? (
                              <Typography style={webStyle.dotStyle}>
                                {values.DMVFileName}
                              </Typography>
                            ) : 
                            <Typography style={webStyle.dotStylePlaceholder}>
                            Upload Document
                          </Typography>
                          }
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                              className="button-icon"
                            />
                          </div>
                        </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="DMVDoc" component="div" />
                  </div>
                </Box>
                <Box style={webStyle.marginBottomm}>
                  <Typography style={webStyle.FieldHeader2}>
                    Driving licence picture upload
                  </Typography>
                  <Field name="DLicensePic">
                    {({ field, form }: any) => (
                      <Box>
                        <input
                          id="file-upload-DLicensePic"
                          data-test-id="DLicensePic"
                          type="file"
                          multiple // Allow multiple file selection
                          style={{ display: 'none' }}
                          placeholder="jkjsdbfkjsd"
                          onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                            const files = event.target.files ? Array.from(event.target.files) : [];
                            setFieldValue("DLicensePic", files);  // Store files as an array
                            await validateForm();

                            // Set file names for display
                            if (files.length) {
                              const fileNames = files.map(file => file.name).join(', ');
                              setFieldValue("DLicenseFileName", fileNames);
                            }
                          }}
                        />
                        <label htmlFor="file-upload-DLicensePic">
                          <div
                            style={{
                              border: errors.DLicensePic && touched.DLicensePic ? '1px solid #F87171' : '1px solid #CBD5E1'
                            }}
                            className="button-style"
                          >
                            {values.DLicenseFileName ? (
                              <Typography style={webStyle.dotStyle}>
                                {values.DLicenseFileName}
                              </Typography>
                            ): 
                            <Typography style={webStyle.dotStylePlaceholder}>
                                Upload Document
                              </Typography>
                            }
                            <img
                              src={fileUpload.default}  // Replace with the path to your image
                              alt="icon"
                              className="button-icon"
                            />
                          </div>
                        </label>
                      </Box>
                    )}
                  </Field>
                  <div style={webStyle.ErrorMessage}>
                    <ErrorMessage name="DLicensePic" component="div" />
                  </div>
                </Box>

                <FormControl fullWidth margin="normal" style={{marginTop: "0px"}}>
                  <MyFormLabel>Vehicle Type</MyFormLabel>
                  <TextFieldCustom
                    variant="outlined"
                    fullWidth
                    required
                    placeholder="Select Vehicle type..."
                    type="tel"
                    name="AdditionalField"
                    onClick={this.onFieldClicked}
                    value={this.state.selectedVehicle}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.fieldClick ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </InputAdornment>
                      )
                    }}
                  />
                  {this.state.fieldClick &&
                    <AddWrapper>
                      {this.state.vehicleOption &&
                        this.state.vehicleOption.map((data) => (
                          <div onClick={() => handleSelectVehicle(data.id, data.name)} key={data.id} style={{ cursor: "pointer", padding: "0px 16px 0px 16px", zIndex: 1, display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", height: "72px", backgroundColor: data.isSelected ? "#ECFDF5" : "#FFFFFF", color: "#0F172A", fontSize: "16px", fontWeight: 400 }} >
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ height: "48px", width: "80px", alignContent: "center" }}><img src={data.img.default} alt="logo" /></div>
                              <div style={{ height: "100%", display: "flex", alignItems: "center" }}>{data.name}</div>
                            </div>
                            {data.isSelected &&
                              <div>
                                <img src={data.tickSign.default} alt="ticksign" />
                              </div>
                            }
                          </div>
                        ))

                      }
                    </AddWrapper>}
                </FormControl>
                <br />
                <Button color="primary" variant="contained" style={webStyle.ButtonStyle3} data-test-id='LogIn2' fullWidth
                  type="submit">Continue</Button>

              </Form>)
            }}

          </Formik>

        </div>

        <div className="right-half">
          <div style={{
            backgroundColor: '#ECFDF5',
            height: '85%',
            width: '100%'
          }}>
            <img src={Logo.default} alt="Logo" />
          </div>
          <div className="outer-div">
            <div style={{ ...webStyle.InnerFirst, marginBottom: '15px' }}>
              Earn on the Road, Drive with Freedom
            </div>
            <div style={{ ...webStyle.InnerFirst }}>
              Non-medical transport
            </div>
          </div>
        </div>
      </Box> 
      </div>
    );
  }
}

export default MyFormComponent;