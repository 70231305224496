import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  select: string;
  deletePopUp:boolean;
  rideId:any;
  endPopUp:boolean;
  isToastOpen1:boolean;
  isToastOpen2:boolean;
  errorToast1:boolean;
  errorToast2:boolean;
  startlocation: string,
  destinationlocation:string,
  // Customizable Area End
}
interface SS {
  id: any;
}
export default class MapsdriverController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.navigateToAppointmentList = this.navigateToAppointmentList.bind(this);
    this.navigateToReviews = this.navigateToReviews.bind(this);
    this.navigateToDriverincomingjob = this.navigateToDriverincomingjob.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.subScribedMessages = [];
    this.state = {
      select: "",
      deletePopUp:false,
      rideId:null,
      endPopUp:false,
      isToastOpen1:false,
      isToastOpen2:false,
      errorToast1:false,
      errorToast2:false,
      startlocation: '',
      destinationlocation:'',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.CompleteRideApiCallId) {
        if (responseJson && responseJson.meta?.message==="Ride completed successfully") {
          this.setState({ endPopUp: false,isToastOpen1:true, })
         
        }
        if (responseJson.errors) {
          this.setState({ endPopUp: false, errorToast1: true });
        }
      }
      if (apiRequestCallId === this.DeleteRideApiCallid) {
       
        if (responseJson && responseJson.message==="Ride cancelled successfully") {
          this.setState({ deletePopUp: false,isToastOpen2:true, })
        }
        if (responseJson.errors) {
          this.setState({ deletePopUp: false, errorToast2: true });
        }
      }
      if (apiRequestCallId === this.orderSummaryApiCallId) {
        if (responseJson && responseJson.data) {
            this.processResponseData(responseJson);
        }

    }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  processResponseData(responseJson:any) {
    this.setState({
        startlocation: responseJson.data?.attributes?.start,
        destinationlocation: responseJson.data?.attributes?.destination
    });
}
  handleToastClose = () => {
    this.setState({ isToastOpen1: false })
    this.navigateToReviews();
  }
  handleToastClose2 = () => {
    this.setState({ isToastOpen2: false })
    this.navigateToAppointmentList();
  }
  handleErrorClose1=()=>{
    this.setState({ errorToast1: false })

  }
  handleErrorClose2=()=>{
    this.setState({ errorToast2: false })

  }
  DeleteRideApiCallid= "";
  CompleteRideApiCallId="";
  orderSummaryApiCallId="";
  async componentDidMount() {
    super.componentDidMount();
    const rideId=localStorage.getItem("RideNumber")
    this.setState({rideId},()=>{    this.fetchRidedata()
    })
  }
  fetchRidedata = () => {
    const token=localStorage.getItem("token")
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.orderSummaryApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.ordersummaryapi}${this.state.rideId}`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};
  navigateToAppointmentList() {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "AppointmentList");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToReviews() {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "Reviews");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  navigateToDriverincomingjob() {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "DriverIncomingJobRequest");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  handleCancelModal=()=>{
    this.setState({deletePopUp:true})
  }
  handleEndModal=()=>{
    this.setState({endPopUp:true})
  }
  onCloseDeletePopup=(e:any)=>{
    e?.stopPropagation();
    this.setState({ deletePopUp: false });
    // this.setState({ rideId: null });
  }
  onCloseEndPopup=(e:any)=>{
    e?.stopPropagation();
    this.setState({ endPopUp: false });
    // this.setState({ rideId: null });
  }
  onDelete=()=>{
    const token = localStorage.getItem("token")
    const header = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("status","cancelled");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.DeleteRideApiCallid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteapiendpoint}${this.state.rideId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  onEnd=()=>{
    const token = localStorage.getItem("token")
    const header = {
      token: token,
    };
    const formdata = new FormData();
    formdata.append("status","completed");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CompleteRideApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.acceptrideendpoint}${this.state.rideId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
 
  }
}

 // Customizable Area End

