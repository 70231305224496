import React from "react";
// Customizable Area Start
import { Box, Button, Typography,styled } from '@mui/material';
import LandingPageController, { configJSON } from "./LandingPageController";
import { landingBackgroundImg } from "./assets";
// Customizable Area End


export default class LandingPageWeb extends LandingPageController {
  render() {
    // Customizable Area Start
    return (
      <HomePage>
        <BackgroundImage src={landingBackgroundImg.default} alt="bg-img" />
        <LandingPageText1 data-testId="textID">{configJSON.yourSafe}</LandingPageText1>
        <LandingPageText2>{configJSON.nonMedical}</LandingPageText2>
        <SignUpButton data-testId="signupID" onClick={() => this.goToSignUp("SignUpRider")}>
          {configJSON.signUp}
        </SignUpButton>
        <SignInButton data-testId="signinID" onClick={() => this.goToSignUp("EmailAccountLoginBlock")}>
          Log In
        </SignInButton>
      </HomePage>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const HomePage = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#ECFDF5",
});

const BackgroundImage = styled('img')({
  height: "64%",
  "@media (max-width: 960px)": {
    height: "45%",
    width: "100%"
  }
});

const LandingPageText1 = styled(Typography)({
  fontSize: "23px",
  fontFamily: "Inter, sans-serif",
  color: "#1D6FB8",
  "@media (max-width: 960px)": {
    fontSize: "18px"
  }
});

const LandingPageText2 = styled(Typography)({
  fontFamily: "Inter, sans-serif",
  fontSize: "22px",
  color: "#1D6FB8",
  "@media (max-width: 960px)": {
    fontSize: "18px"
  }
});

const SignUpButton = styled(Button)({
  width:"100%",
  maxWidth: "440px",
  height: "56px",
  textAlign:"center",
  backgroundColor: "#1D6FB8",
  border: "1px solid #1D6FB8",
  fontSize: "20px",
  color: "white",
  fontWeight:400,
  borderRadius: 8,
  cursor: "pointer",
  margin: "10px 0px",
  textTransform: 'none',
  "@media (max-width: 960px)": {
    width: "70%",
  },
  '&:hover':{
    backgroundColor: "#1D6FB8",
  }
});

const SignInButton = styled(Button)({
  width:"100%",
  maxWidth: "440px",
  height: "56px",
  fontWeight:400,
  backgroundColor: "#FFFFFF",
  border: "1px solid #1D6FB8",
  fontSize: "20px",
  color: "#1D6FB8",
  borderRadius: 8,
  cursor: "pointer",
  textTransform: 'none',
  "&:hover":{
    backgroundColor: "#FFFFFF",
  },
  "@media (max-width: 960px)": {
    width: "70%",
  }
});
// Customizable Area End
