export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const Logo = require("../assets/Logo.png");
export const fileUpload = require("../assets/fileee.jpg");
export const dropdown = require("../assets/dropdown.png");
export const error = require("../assets/error.png");
export const retry = require("../assets/retry.png");
export const sucess = require("../assets/sucess.png");
export const mainLogo = require("../assets/mainLogo.png");
export const success = require("../assets/cuate.png");
export const driverIssues = require("../assets/driverIssues.png");
export const driverReject = require("../assets/driverReject.png");
export const driverReview = require("../assets/driverReview.png");
export const driverSucess = require("../assets/driverSucess.png");
export const rightLogo = require("../assets/rightLogo.png");
export const doller = require("../assets/doller.png");
export const ambulatory = require("../assets/Ambulatory.png");
export const wheel = require("../assets/wheelchair.png");
export const gurney = require("../assets/gurney.png");
export const tickSign = require("../assets/TickSign.png");
export const location = require("../assets/Location.png");




export const image1 = require("../assets/Vehicle.png");
export const image2 = require("../assets/image_.png");
export const image3 = require("../assets/image1_.png");
export const check = require("../assets/check.png");

