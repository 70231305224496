import React, { Component } from "react";
import {Logo} from '../../blocks/email-account-registration/src/assets'
import { Box, Button, TextField, Checkbox, FormControlLabel, styled, Typography, LinearProgress, Radio, RadioGroup, FormControl, FormLabel, IconButton ,InputAdornment, Dialog, DialogActions,DialogContent, DialogTitle, } from "@mui/material";
import { fileUpload,dropdown} from "../../blocks/email-account-registration/src/assets";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CSSProperties } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

interface MyFormProps {
  initialValues: any; 
  Submit1: (values: any) => void; 
  back:() => void;
}

interface MyFormState {
  isFocusmobNo: boolean;
  isFocusInsuranceNo: boolean;
  ModalOpen: boolean;
  optionsList1: string[];
  isFocusvehType:boolean;

  isFocusvehMake:boolean;

      isFocusvehModel:boolean;
      isFocusvehYear:boolean;
      isFocusvehSeats:boolean;
      isFocusvehLicPlate:boolean;
}


const LogoContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    textAlign: "center",
  });
  
  const LogoTextBottom1 = styled("div")({
    marginTop: "16px",
    fontWeight: 400,
    fontSize: "18px",
    color: "white",
  });
  
  const RoadText = styled(Typography)({
    color: "white",
    fontWeight: 400,
    fontSize: "30px",
    marginTop: "40px",
  });
  
  const ProgressBarContainer = styled("div")({
    width: "100%",
    margin: "20px 0",
  });
  
  const Heading = styled(Typography)({
    fontWeight: 400,
    fontSize: "30px",
    color: "#1D6FB8",
    width: "100%",
    marginBottom: "16px",
  });
  
  const CustomLinearProgress = styled(LinearProgress)({
    height: "10px",
    borderRadius: "5px",
    backgroundColor: "#E2E8F0",
    '& .MuiLinearProgress-bar': {
        backgroundColor: "#4caf50",
    },
  });
  
  
  const validationSchema = Yup.object().shape({

  vehMake: Yup.string()
  .required('Vehicle Type is required')
  .max(20, 'Vehicle Type cannot be more than 20 characters'),
  vehModel: Yup.string()
    .required('Vehicle Model is required')
    .max(25, 'Vehicle Model cannot be more than 25 characters'),
  
    vehYear: Yup.string()
    .required('Vehicle Year is required')
    .matches(/^\d{4}$/, 'Vehicle Year must be a 4-digit number') // Ensure it's a 4-digit numeric value
    .max(4, 'Vehicle Year must be 4 digits')
    .test(
      'is-not-future-year',
      'Vehicle Year cannot be in the future',
      (value) => {
        const currentYear = new Date().getFullYear();
        return value && parseInt(value, 10) <= currentYear; // Compare with the current year
      }
    ), 
  
    vehSeats: Yup.number()
    .required('Number of Seats is required')
    .min(1, 'Number of Seats must be at least 1')
    .integer('Number of Seats must be an integer')
    .max(200, 'Number of Seats cannot be more than 25'),
  
  vehLicPlate: Yup.string()
    .required('Vehicle Licence Plate is required')
    .matches(/^[A-Za-z0-9]+$/, 'Vehicle License Plate can only contain letters and numbers') // Only alphanumeric characters
    .max(15, 'Vehicle Licence Plate cannot be more than 15 characters'),
  });
  const webStyle = {
    InnerFirst:{color:'#1D6FB8',fontSize:'20px',fontWeight:400,marginBottom:'15px'},
    ErrorMessage:{ color:'#DC2626', fontSize: '12px',fontWeight:'400', marginTop: '5px',marginBottom:'5px' },
    FieldHeader:{
     fontSize: "14px",
     fontWeight: 700,
     paddingBottom:'5px',
   MarginTop:'5px'
    },
    ButtonStyle3:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
    ButtonStyle23:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
    marginBottomm3:{ marginBottom: "15px" },
    InputFIeldStyle3:{width:'100%',height:'56px',borderRadius:'8px',fontSize:'16px'},
      
     };
class MyFormComponent extends Component<MyFormProps, MyFormState> {
  state: MyFormState = {
    isFocusmobNo: false,
    isFocusInsuranceNo: false,
    ModalOpen: false,
    optionsList1: ['Sedan','Station wagon','Hatchback','SUV','Minivan','other'],
    isFocusvehType:false,
    isFocusvehMake:false,
    isFocusvehModel:false,
    isFocusvehYear:false,
    isFocusvehSeats:false,
    isFocusvehLicPlate:false, 
  };

  handleDialogClose2=()=>{
    this.setState({ModalOpen:false})
      }
    handleDialogOpen2=()=>{
        this.setState({ModalOpen:true})
          }
          formatVehType = (vehType:string) => {
            if (!vehType) return 'Vehicle Type'; // Return default text if vehType is not provided
        
            const formattedType = vehType.replace(/_/g, ' ').toLowerCase();
            return formattedType
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
          };
  render() {
    const { initialValues, Submit1 ,back} = this.props;

    return (
      <div className="outer-container">
        <Box
        sx={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
          flexDirection: { xs: "column", md: "row" }, // column on small screens, row on larger screens
        }}
      >
        <div className="left-half">

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => {
            Submit1(values)
            // this.confirmPass(values)
          }}   enableReinitialize={true}>
            {(props) => {
              return (<Form translate={undefined} className="custom-style">
                <Heading data-testid="signup-heading">
                  Create your E-Ryde account
                </Heading>

                <ProgressBarContainer>
                  <Typography variant="body2" style={{ fontWeight: 400, fontSize: "18px", color: "rgba(29, 111, 184, 1)", marginBottom: "8px" }} data-testid="progress-step">
                    Step 3 of 4
                  </Typography>
                  <CustomLinearProgress variant="determinate" value={75} data-testid="progress-bar" />
                </ProgressBarContainer>
                <br />
                
                <Box style={webStyle.marginBottomm3}>
                  <Typography style={webStyle.FieldHeader}>
                  Vehicle Make
                  </Typography>

                  <input
                    id="vehMake"
                    data-test-id="vehMake"
                    name="vehMake"
                    // className="placeholder-styled"
                    value={props.values.vehMake}
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("vehMake", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusvehMake ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusvehMake: true })}
                    onBlur={() => this.setState({ isFocusvehMake: true })}
                    style={{
                      ...webStyle.InputFIeldStyle3,
                      border: props.errors.vehMake && props.touched.vehMake ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.vehMake && props.touched.vehMake && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="vehMake" />
                    </div>
                  )}
                </Box>
                <Box style={webStyle.marginBottomm3}>
                  <Typography style={webStyle.FieldHeader}>
                  Vehicle Model
                  </Typography>
                  <input
                    id="vehModel"
                    name="vehModel"
                    data-test-id="vehModel"
                    value={props.values.vehModel}
                    // className="placeholder-styled"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("vehModel", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusvehModel ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusvehModel: true })}
                    onBlur={() => this.setState({ isFocusvehModel: true })}
                    style={{
                      ...webStyle.InputFIeldStyle3,
                      border: props.errors.vehModel && props.touched.vehModel ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.vehModel && props.touched.vehModel && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="vehModel" />
                    </div>
                  )}

                </Box>
                <Box style={webStyle.marginBottomm3}>
                  <Typography style={webStyle.FieldHeader}>
                  Vehicle Year
                  </Typography>

                  <input
                    id="vehYear"
                    name="vehYear"
                    data-test-id="vehYear"
                    value={props.values.vehYear}
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("vehYear", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusvehYear ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusvehYear: true })}
                    onBlur={() => this.setState({ isFocusvehYear: true })}
                    style={{
                      ...webStyle.InputFIeldStyle3,
                      border: props.errors.vehYear && props.touched.vehYear ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.vehYear && props.touched.vehYear && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="vehYear" />
                    </div>
                  )}

                </Box>
                <Box style={webStyle.marginBottomm3}>
                  <Typography style={webStyle.FieldHeader}>Number of seats</Typography>
                  <Field
                    id="vehSeats"
                    name="vehSeats"
                    data-test-id="vehSeats"
                    type="number"
                    placeholder="Type here..."
                    min="0" // Prevents negative values
                    onChange={(e:any) => {
                      props.setFieldValue("vehSeats", e.target.value);
                    }}
                    className={this.state.isFocusvehSeats ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusvehSeats: true })}
                    onBlur={() => this.setState({ isFocusvehSeats: false })}
                    style={{
                      ...webStyle.InputFIeldStyle3,
                      border: props.errors.vehSeats && props.touched.vehSeats ? '1px solid #F87171' : '1px solid #CBD5E1',
                    }}
                  />
                  {props.errors.vehSeats && props.touched.vehSeats && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="vehSeats" />
                    </div>
                  )}
                </Box>
                <Box style={webStyle.marginBottomm3}>
                  <Typography style={webStyle.FieldHeader}>
                  Vehicle licence plate
                  </Typography>

                  <input
                    id="vehLicPlate"
                    name="vehLicPlate"
                    data-test-id="vehLicPlate"
                    value={props.values.vehLicPlate}
                    // className="placeholder-styled"
                    placeholder="Type here..."
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("vehLicPlate", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusvehLicPlate ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusvehLicPlate: true })}
                    onBlur={() => this.setState({ isFocusvehLicPlate: true })}
                    style={{
                      ...webStyle.InputFIeldStyle3,
                      border: props.errors.vehLicPlate && props.touched.vehLicPlate ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.vehLicPlate && props.touched.vehLicPlate && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="vehLicPlate" />
                    </div>
                  )}

                </Box>
               
                <br />
                <Button color="primary" variant="contained" style={webStyle.ButtonStyle3} data-test-id='LogIn3' fullWidth
                  type="submit">Continue</Button>
              
              </Form>)
            }}

          </Formik>

        </div>

        <div className="right-half">
          <div style={{backgroundColor:'#ECFDF5',
          height:'85%',
            width:'100%'
            }}>
        <img src={Logo.default} alt="Logo" />
        </div>
         <div className="outer-div">
         <div style={{...webStyle.InnerFirst,marginBottom:'15px'}}>
         Earn on the Road, Drive with Freedom
         </div>
         <div style={{...webStyle.InnerFirst}}>
         Non-medical transport
         </div>
         </div>
        </div>
      </Box>
      </div>
    );
  }
}

export default MyFormComponent;
