import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  openmodal: boolean;
  message: string;
  error: boolean;
  errormessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class Contactus2Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  userprofiledetailsApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";
    this.userprofiledetailsApiCallId = ""

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      openmodal: false,
      message: "",
      error: false,
      errormessage: "",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
      this.getUserprofiledetails()
  
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);


      if (apiRequestCallId === this.userprofiledetailsApiCallId) {
        const user = localStorage.getItem("role_id")
        if(user){
          this.hanldeProfileUser(user,responseJson)
        }
      }


      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.addContactApiCallId) {
          this.setState({ openmodal: true, error: false, message: "", phoneNumber: "", email: "", name: "" })
        }
      }
      else if (responseJson && responseJson.errors) {
        this.handleComplexity(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  CloseModal = () => {
    this.setState({ openmodal: false })
    this.navigateToLandingPage()
  }
  navigateToLandingPage=()=>{
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  handleSubmit = () => {
    this.addQueryApi()
  }

  hanldeProfileUser = (user: string, responseJson: any) => {
    if (user == "rider") {
      let { email, first_name, last_name } = responseJson?.data
      let { mobile_number } = responseJson?.data?.rider_detail
      this.setState({
        name: `${first_name} ${last_name}`,
        email: email || "",
        phoneNumber: mobile_number || "",
      })
    } else {
      let { email, first_name, last_name  } = responseJson?.data?.attributes?.account?.attributes
      let { mobile_number } = responseJson?.data?.attributes;
      const fullName = this.getFullName(first_name,last_name);
      this.setState({
        name:fullName|| "",
        email: email || "",
        phoneNumber: mobile_number || "",
      })
    }
  }

  addQueryApi = () => {
    const userId = localStorage.getItem("userId")
    if ( userId) {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber,
          message: this.state.message.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getContactUsAPiEndPoint}?id=${userId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };


  getUserprofiledetails = () => {
    const id=localStorage.getItem("userId")
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userprofiledetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getuserprofilendpoint2}?id=${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleComplexity = (responseJson: any) => {
    if (responseJson.errors) {
      responseJson.errors.forEach((error: any) => {
        if (error.contact) {
          // console.log(error.concat)
          if(error.contact[0]  == "Message can't be blank"){
            this.setState({ openmodal: true, error: true, errormessage: (configJSON.errorTitle, "No text in message") })
          }else{
            this.setState({ openmodal: true, error: true, errormessage: (configJSON.errorTitle, error.contact[0]) })
          }
         
        }
      });

    }
  }

  redirectToDashboard = (route: string) => {
    const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigate.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigate);
  }
  getFullName = (firstName:any, lastName:any) => {
    return `${firstName || ''} ${lastName || ''}`.trim() || '';
  };
  // Customizable Area End
}
