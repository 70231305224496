import React from "react";
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { mapp_Imag, backicon, greenlocation, redlocation } from "./assets";
import {
 Button,Typography,
styled, 
Dialog,
 DialogContent,
 DialogActions,
 Snackbar
} from '@mui/material';
import MapsdriverController, { Props, } from "./MapsdriverController";
const configJSON = require("./config");
import { danger } from './assets';
import MuiAlert from '@mui/material/Alert';
import "../../email-account-registration/src/EmailAccountRegistratin.css"
// Customizable Area End
export default class Mapsdriver extends MapsdriverController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div style={styles.container}>
        <NavigationMenu navigation={this.props.navigation} id={""} index={1} />
        <main style={{ width: "100%" }}>
          <div style={styles.mapContainer}>
            <button
              style={styles.backButton}
              onClick={this.navigateToAppointmentList}
            >
              <img src={backicon.default} style={styles.locationicon} />
            </button>
            <img src={mapp_Imag.default} alt="Map" style={styles.mapImage} />
          </div>
          <div style={styles.rideDetails}>
            <h2 style={styles.heading}>Ride in progress</h2>
            <p style={styles.paragraphone}>
              You’ll reach your destination by <span>03:30 pm</span>
            </p>
            <div>
              <h3 style={styles.heading}>Trip Details</h3>
              <div style={{...styles.containerone,marginBottom:'20px'}}>
                <img src={redlocation.default} style={styles.locationimg} />
                <div>
                  <p style={styles.paragraph}>
                    Drop Off At:
                  </p>
                  <p style={{...styles.paragraphdrop,}}>
                    {this.state.destinationlocation}
                  </p>
                </div>
              </div>
              <div style={styles.containerone}>
                <img src={greenlocation.default} style={styles.greenlocationimg} />
                <div>
                  <p style={styles.paragraph}>
                    
                      Pick Up From:
                  </p>
                  <p style={styles.paragraphdrop}>
                   {this.state.startlocation}</p>
                </div>
              </div>
              </div>
              <div style={styles.actionButtons}>
                <StyledButton
                  onClick={this.handleEndModal}

                >
                  End Ride
                </StyledButton>
                <StyledCancelButton
                  onClick={this.handleCancelModal}

                >
                  Cancel Ride 
                </StyledCancelButton>
              </div>
            </div>
        </main>
        <StyledDialog open={this.state.deletePopUp} onClose={(e)=>{this.onCloseDeletePopup(e)}}>
          <StyledDialogContent>
            <img src={danger.default} alt="Delete Icon" />
            <Typography variant="h6" gutterBottom style={{paddingTop:'20px'}}>
            Are you sure you want to cancel this ride?
            </Typography>
          </StyledDialogContent>
          <ActionsContainer>
          <StyledCancelButton2 onClick={(e)=>{this.onCloseDeletePopup(e)}} data-test-id="CancelButton">No</StyledCancelButton2>
          <StyledDeleteButton onClick={this.onDelete}>Yes</StyledDeleteButton>
          </ActionsContainer>
        </StyledDialog>
        <StyledDialog open={this.state.endPopUp} onClose={(e)=>{this.onCloseEndPopup(e)}}>
          <StyledDialogContent>
            <img src={danger.default} alt="Delete Icon" />
            <Typography variant="h6" gutterBottom style={{paddingTop:'20px'}}>
            Are you sure you want to End this ride?
            </Typography>
          </StyledDialogContent>
          <ActionsContainer>
          <StyledCancelButton2 onClick={(e)=>{this.onCloseEndPopup(e)}} data-test-id="CancelButton">No</StyledCancelButton2>
          <StyledDeleteButton onClick={this.onEnd}>Yes</StyledDeleteButton>
          </ActionsContainer>
        </StyledDialog>
        <Snackbar
          open={this.state.isToastOpen1}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {configJSON.complete}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.isToastOpen2}
          autoHideDuration={3000}
          onClose={this.handleToastClose2}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {configJSON.cancel}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.errorToast1}
          autoHideDuration={3000}
          onClose={this.handleErrorClose1}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="error"
            sx={{ backgroundColor:  "rgb(208, 52, 52)", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
          {configJSON.completeError}
          </MuiAlert>
        </Snackbar>
        <Snackbar
          open={this.state.errorToast2}
          autoHideDuration={3000}
          onClose={this.handleErrorClose2}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="error"
            sx={{ backgroundColor:  "rgb(208, 52, 52)", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
           {configJSON.cancelError}
          </MuiAlert>
        </Snackbar>
      </div>);
  }
  // Customizable Area End
}
// Customizable Area Start
const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: "16px", // Apply border radius to the dialog
  },
});
const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "30vw",
  maxWidth: "500px",
  minWidth: "250px",
  textAlign: "center",
  boxSizing: "border-box",
  padding: "20px", 
});

const StyledButton2 = styled(Button)({
  display: "flex",
  alignItems: "center",
  width: "100%", // Full width for better click area
  justifyContent: "flex-start", // Align icon and text to the left
  padding: "10px",
  fontSize: "18px",
  fontWeight: 400,
  paddingLeft:'10%',
  textTransform: "none",
  color:'#0F172A'
});
const ActionsContainer = styled(DialogActions)`
  justify-content: center;
  padding-bottom: 16px;
  padding-Top:20px;
`;
const StyledCancelButton2 = styled(Button)(({ theme }) => ({
  backgroundColor: "#E7E5E4",
  color: "#64748B",
  borderRadius: "10px",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "100px",
  marginRight:'20px',
  textTransform: "none", // Remove uppercase text

  "&:hover": {
    backgroundColor: "#D6D3D1",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 12px",
    minWidth: "80px",
  },
}));

// Styled Delete Button
const StyledDeleteButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#DC2626",
  color: "#FFFFFF",
  borderRadius: "10px",
  padding: "8px 16px",
  fontSize: "14px",
  fontWeight: "500",
  minWidth: "100px",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#B91C1C",
  },

  [theme.breakpoints.down("sm")]: {
    fontSize: "12px",
    padding: "6px 12px",
    minWidth: "80px",
  },
}));
const StyledButton = styled(Button)({
  backgroundColor: "#1D6FB8",
  color: "white",
  border: "none",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "18px",
  height: "42px",
  width: "185px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#13578a", // Darker shade on hover
  },
});
const StyledCancelButton = styled(Button)({
  backgroundColor: "#fff",
  color: "#DC2626",
  border: "1px solid red",
  padding: "10px 20px",
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "18px",
  height: "42px",
  width: "185px",
  fontWeight: "400",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f5f5f5", 
  },
});
const styles = {
  container: {
    backgroundColor: "#f2fcf5",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    width: "100%",
  },
  mapContainer: {
    width: "95%",
    height: "auto",
    margin: "20px auto",
    position: "relative" as const,
    borderRadius: "10px",
    overflow: "hidden",
  },
  backButton: {
    position: "absolute" as const,
    top: "25px",
    left: "1px",
    color: "black",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  },
  mapImage: {
    width: "100%",
    height: "468px",
    borderRadius: "10px",
  },
  rideDetails: {
    width: "90%",
    maxWidth: "100%",
    margin: "20px auto",
  },
  heading: {
    fontSize: "18px",
    fontWeight: "700",
    marginBottom: "10px",
  },
  paragraphone: {
    fontSize: "16px",
    marginBottom: "10px",
    borderBottom: "1px solid #E2E8F0",
    display: "inline-block",
    paddingBottom: "10px",
    color: "#1E293B",
    fontweight: '400'
  },
  paragraph: {
    fontSize: "16px",
    fontWeight: "700",
    marginBottom: "8px",
    color: '#334155',
    paddingLeft: '20px',
  },
  paragraphdrop: {
    fontSize: "16px",
    fontWeight: "400",
    margin: "6px 0",
    color: '#64748B',
    paddingLeft: '20px',
  },
  actionButtons: {
    display: "flex",
    marginTop: "20px",
    gap: "20px",
  },
  continueButton: {
    backgroundColor: "#1D6FB8",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
    height: "42px",
    width: '185px',
  },
  locationicon: {
    width: "20px",
    height: '22px',
  },
  locationimg: {
    width: "16px",
    height: '20px',
  },
  greenlocationimg: {
    width: "16px",
    height: '20px',
  },
  cancelButton: {
    backgroundColor: "#fff",
    color: "#DC2626",
    border: "1px solid red",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
    height: "42px",
    width: '185px',
    fontWeight: "400",
  },
  containerone: {
    display: 'flex',
    alignItems: 'center',
  },
};
// Customizable Area End

