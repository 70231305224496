// Customizable Area Start
import React from "react";
import PlanYourRideController, { Props } from "./PlanYourRideController.web";
import { location,location2, search, expand, collapse, freq, send,danger, search2, home, work, carImage, sucessDialog, amber,doller,checkboxEmpty,checkboxFilled,deleteImg,edit ,hide} from "../assets";
import "../../../email-account-registration/src/EmailAccountRegistratin.css"
import RideOptions from "./RideOptions.web";
import {
    Dialog, DialogTitle, IconButton, TextField, Divider, List, ListItem, ListItemText, ListItemIcon, Typography, styled, InputAdornment, DialogContent, Grid,
    FormControlLabel, Checkbox, Box, Modal, Button,
    Popover,
    MenuItem,DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const configJSON = require("../../src/config");
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// Customizable Area End

// Customizable Area Start
const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        borderRadius: '15px',
    },
    '& .MuiInputBase-input::placeholder': {
        color: '#94A3B8',
        fontSize: '14px',
        fontWeight: 400,
        opacity: 1,
    },
});
const TitleTypography = styled(Typography)(({ theme }) => ({
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '22px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '22px',
    },
}));
const CarTitle = styled(Typography)(({ theme }) => ({
    color: '#0F172A',
    fontSize: '22px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '22px',
    },
}));
const CarTitle2 = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '3px', 
    color: '#0F172A',
    fontSize: '22px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '22px',
    },
}));

const CarDistance = styled(Typography)(({ theme }) => ({
    color: '#64748B',

    fontSize: '18px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '18px',
    },
}));
const commonPrimaryTypographyProps = {
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',

};

const Continuebox = styled(Typography)({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    height: "60.4px",
    alignItems: "center",

});

const commonSecondaryTypographyProps = {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
};
const customTypographyStyles = {
    fontSize: '12px',
    fontWeight: 700,
};
const StyledDialog = styled(Dialog)(({ theme }) => ({
    scrollbarWidth: 'none', msOverflowStyle: 'none',

    '& .MuiDialog-paper': {
        borderRadius: '16px',
        scrollbarWidth: 'none', msOverflowStyle: 'none',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
        padding: theme.spacing(1),
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        '-ms-overflow-style': 'none',  // Hide scrollbar in Internet Explorer
        'scrollbar-width': 'none',
    },
}));
const StyledDialogContent2 = styled(DialogContent)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30vw",
    maxWidth: "500px",
    minWidth: "250px",
    textAlign: "center",
    boxSizing: "border-box",
    padding: "20px", 
  });
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({

    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto', // Keep overflow settings
    '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar in WebKit browsers
    },
    '-ms-overflow-style': 'none', // Hide scrollbar in Internet Explorer
    'scrollbar-width': 'none',
}));
const carData = [
    { id: 1, name: 'Sedan', image: 'car1.jpg', distance: '15 km', price: '22' },
    { id: 2, name: 'SUV', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Hatchback', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Minivan', image: 'car2.jpg', distance: '20 km', price: '80' },
    { id: 2, name: 'Other', image: 'car2.jpg', distance: '20 km', price: '80' },
    // Add more cars as needed
];;


const ResponsiveErrorText = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    color: 'DC2626',
    fontSize: '18px',
    fontWeight: '400',

    [theme.breakpoints.down('sm')]: {
        fontSize: '14px', // Adjust for smaller screens
    },
}));
const ResponsiveErrorText2 = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'DC2626',
    fontSize: '18px',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
        fontSize: '14px', // Adjust for smaller screens
    },
}));

// Customizable Area End

export default class PlanYourRide extends PlanYourRideController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    scheduleRideselection = () => {
        return (
            <>
                <div className="input-container">
                    <img src={search2.default} alt="Key Icon" className="input-icon" />
                    <input
                        type="text"
                        className="styled-input"
                        placeholder={this.state.showstartlocation ? '' : configJSON.Start}
                        value={this.state.startlocation}
                        onChange={(e) => this.handleStartLocationChange(e.target.value)}
                        data-test-id="selectstartdate"
                    />
                </div>
                {this.state.startlocationerror && <h1 style={webStyle.errortext}>{configJSON.startdestination}</h1>}
                <div className="input-container">
                    <img src={send.default} alt="Key Icon" className="input-icon" />
                    <input
                        type="text"
                        className="styled-input"
                        // value={this.state.showendLocation ? this.state.destinationlocation : ''}
                        placeholder={this.state.showendLocation ? '' : configJSON.Destination}
                        // onFocus={this.openStartDestPopup}
                        value={this.state.destinationlocation}
                        onChange={(e) => this.handleDestinationLocationChange(e.target.value)}
                        data-test-id="selectenddate"
                    />
                </div>
                {this.state.endlocationerror && <h1 style={webStyle.errortext}>{configJSON.destination}</h1>}
                <div className={`input-field ${this.state.expandedField3 ? 'expanded' : ''}`} onClick={this.handleFavPopup}>
                    <img src={location.default} alt="location Icon" className="icon" />
                    <div className="text-wrapper">
                        <span style={webStyle.optiontext}>{configJSON.FavoritePlaces}</span>
                       
                    </div>
                </div>
                <div className={`input-field ${this.state.expandedField4 ? 'expanded' : ''}`} onClick={this.props.opensearch}>
                    <img src={location2.default} alt="location Icon" className="icon2" />
                    <div className="text-wrapper">
                        <span style={webStyle.optiontext}>{configJSON.ChooseFromMap}</span>
                        <img
                            src={this.state.expandedField4 ? collapse.default : expand.default}
                            alt="Expand/Collapse Icon"
                            className="expand-icon"
                        />
                    </div>
                </div>
            </>
        )
    }
    getImage = (type:any) => {
        if (type === "work") {
          return work.default;
        } else if (type === "home") {
          return home.default;
        } else  {
            return location2.default;
          } 
      };
    // Customizable Area End

    render() {

        // Customizable Area Start
        const allLocations = [...this.state.staticLocations, ...this.state.dynamicLocations.map((loc:any) => loc.attributes)];

        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="Planride-outer">
                <div className="outer-div-planride">
                    <div className="innerSecodDiv">
                        {this.scheduleRideselection()}
                        <div className={`input-field ${this.state.expandedField5 ? 'expanded' : ''}`}>
                            <img src={freq.default} alt="location Icon" className="icon" />
                            <div className="text-wrapper" >
                                <span style={webStyle.optiontext} onClick={this.openLocationErrorPopUp}>{configJSON.FrequentlyUsed}</span>
                                <img
                                    src={this.state.expandedField5 ? collapse.default : expand.default}
                                    alt="Expand/Collapse Icon"
                                    className="expand-icon"
                                    onClick={this.openInternetErrorPopUp}
                                />
                            </div>
                        </div>
                        <div className={`input-field ${this.state.expandedField5 ? 'expanded' : ''}`} onClick={this.onCarPopupOpen}>
                            <img src={search.default} alt="location Icon" className="icon" />
                            <div className="text-wrapper" >
                                <span style={webStyle.optiontext}>{this.state.selectedCar.name? this.state.selectedCar.name : `${configJSON.CarType}`}</span>
                                <img
                                    src={this.state.expandedField5 ? collapse.default : expand.default}
                                    alt="Expand/Collapse Icon"
                                    className="expand-icon"
                                    onClick={this.handleField6}
                                />
                            </div>
                        </div>
                        {this.state.cartypeerror && <h1 style={webStyle.errortext}>{configJSON.cartyperror}</h1>}
                        <Dialog
                            open={this.state.isStartDestOpen}
                            onClose={this.onStartDestClose}
                            fullWidth
                            maxWidth="md"
                            PaperProps={{
                                sx: {
                                    width: '50%',
                                    padding: '30px',
                                    borderRadius: '30px',
                                    scrollbarWidth: 'none', msOverflowStyle: 'none',
                                    '@media (max-width: 600px)': {
                                        padding: '15px',
                                        width: '90%',
                                        borderRadius: '10px',
                                    }
                                }
                            }}
                        >
                            <DialogTitle sx={{ display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center' }}>
                                <TitleTypography >
                                    Select Address
                                </TitleTypography>
                                <IconButton onClick={this.onStartDestClose} sx={{ position: 'absolute', right: 0 }}>
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>

                            <Divider />

                            <div style={{ padding: '16px', }}>
                                <StyledTextField
                                    fullWidth
                                    placeholder="Start"
                                    margin="normal"
                                    variant="outlined"
                                    value={this.state.startlocation}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                                        this.setState({ startlocation: sanitizedValue });
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={search2.default} alt="Lock Icon" style={{width:'34px',height:'30px'}} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <StyledTextField
                                    fullWidth
                                    placeholder="Destination"
                                    margin="normal"
                                    variant="outlined"
                                    data-test-id="selectdestination"
                                    value={this.state.destinationlocation}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, '');
                                        this.setState({ destinationlocation: sanitizedValue });
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={send.default} alt="Lock Icon" className="icon" />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>

                            <Divider />

                            <div style={{ padding: '16px' }}>
                                <Typography variant="subtitle1" sx={customTypographyStyles}>Recent Places</Typography>
                            </div>

                            <Divider />

                            <List>
                                {this.state.home.length > 0 && this.state.home.map((item: any, index: any) => (
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <img src={home.default} alt="Lock Icon" className="icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={item.description}
                                                primaryTypographyProps={commonPrimaryTypographyProps}
                                                secondaryTypographyProps={commonSecondaryTypographyProps}
                                            />
                                        </ListItem>
                                    </div>
                                ))}
                                {this.state.work.length > 0 && this.state.work.map((item: any, index: any) => (
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <img src={work.default} alt="Lock Icon" className="icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={item.description}
                                                primaryTypographyProps={commonPrimaryTypographyProps}
                                                secondaryTypographyProps={commonSecondaryTypographyProps}
                                            />
                                        </ListItem>
                                    </div>
                                ))}
                                {this.state.location.length > 0 && this.state.location.map((item: any, index: any) => (
                                    <div key={index}>
                                        <ListItem>
                                            <ListItemIcon>
                                                <img src={location.default} alt="Lock Icon" className="icon" />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={item.description}
                                                primaryTypographyProps={commonPrimaryTypographyProps}
                                                secondaryTypographyProps={commonSecondaryTypographyProps}
                                            />
                                        </ListItem>
                                    </div>
                                ))}
                            </List>
                            <Continuebox onClick={this.onContinue} data-test-id="afterselectingstartanddestination">
                                <Box style={webStyle.oncontinuebox}>
                                    <Button style={webStyle.buttontext}>{configJSON.continue}</Button>
                                </Box>

                            </Continuebox>
                        </Dialog>
                        <StyledDialog open={this.state.isCarPopUpOpen} onClose={this.onCarPopupClose} className="carPopup">
                            <StyledDialogContent>
                                { this.hasCarData()? (
                                    this.state.carData.map((car: any) => (
                                        <Grid
                                            item
                                            xs={12}
                                           
                                            container
                                            style={{
                                                backgroundColor: this.getBackgroundColor(car, this.state.selectedCar2),
                                                marginBottom: '20px',
                                                paddingRight: '0px',
                                                border: this.getBorder(car, this.state.selectedCar2),
                                                borderRadius: this.getBorderRadius(car, this.state.selectedCar2),
                                                padding: '10px',
                                            }}
                                        >
                                            <Grid item xs={4}>
                                                <img
                                                    src={this.getCarImageUrl(car)}
                                                    alt={"car"}
                                                    style={{ width: '100%', height: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={8} container direction="column">
                                                <Grid container justifyContent="space-between">
                                                    <div style={{ marginLeft: '20px' }}>
                                                        <CarTitle>{car.attributes.car_type}</CarTitle>
                                                        <CarDistance variant="body2">{car.attributes.distance}km</CarDistance>
                                                    </div>
                                                    <div style={{ ...webStyle.gridCSS, flexDirection: 'column' }}>
                                                        <CarTitle2>
                                                            <img
                                                                src={doller.default}
                                                                alt="dollar"
                                                                style={{ width: '14px', height: '20px' }}
                                                            />
                                                           {this.getCarPrice(car)}
                                                        </CarTitle2>
                                                        <Checkbox
                                                            checked={this.state.selectedCar2 === car}
                                                            onChange={() => this.handleCarSelect(car)}
                                                            icon={
                                                                <img
                                                                    src={checkboxEmpty.default}
                                                                    alt="unchecked"
                                                                    style={{ width: '24px', height: '24px' }}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <img
                                                                    src={checkboxFilled.default}
                                                                    alt="checked"
                                                                    style={{ width: '24px', height: '24px' }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    <p>No cars available.</p>
                                )}
                            </StyledDialogContent>

                        </StyledDialog>
                        <Dialog
                            open={this.state.LocationErrrorPopUp}
                            onClose={this.closeLocationErrorPopUp}
                            PaperProps={{
                                sx: {
                                    position: 'absolute',
                                    top: '80px',
                                    margin: 0,
                                    transform: 'none',
                                    width: '50%',
                                    borderRadius: '8px',
                                    backgroundColor: '#FEE2E2',
                                    borderLeft: '10px solid #DC2626',
                                    '@media (max-width: 600px)': {
                                        width: '80%',
                                    },
                                }
                            }}
                        >
                            <DialogContent>
                                <ResponsiveErrorText2 >
                                    {configJSON.LocationError}
                                    <IconButton onClick={this.closeLocationErrorPopUp} sx={{ position: 'absolute', right: 0 }}>
                                        <CloseIcon sx={{ color: '#DC2626' }} />
                                    </IconButton>
                                </ResponsiveErrorText2>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={this.state.InternetErrorPopUp}
                            onClose={this.closeInternetErrorPopUp}
                            PaperProps={{
                                sx: {
                                    position: 'absolute',
                                    top: '80px',
                                    margin: 0,
                                    transform: 'none',
                                    width: '50%',
                                    borderRadius: '8px',
                                    backgroundColor: '#FEE2E2',
                                    borderLeft: '10px solid #DC2626',
                                    '@media (max-width: 600px)': {
                                        width: '80%',
                                    },
                                }
                            }}
                        >
                            <DialogContent>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={amber.default} style={{ width: '10%', paddingRight: '10px' }} alt="error Icon" className="icon" />

                                        <ResponsiveErrorText >
                                            {configJSON.ErrorText2}
                                        </ResponsiveErrorText>
                                    </div>
                                    <IconButton onClick={this.closeInternetErrorPopUp} sx={{ position: 'absolute', right: 0 }}>
                                        <CloseIcon sx={{ color: '#DC2626' }} />
                                    </IconButton>
                                </div>
                            </DialogContent>
                        </Dialog>
                        <StyledDialog2 open={this.state.favLoc} onClose={this.onClose}>
                            <DialogContent>
                                <List>
                                    {allLocations.map((location) => (
                                        <StyledListItem key={location.id}>
                                            {/* Left Side (Icon + Name + Address) */}
                                            <InfoContainer>
                                                <NameContainer>
                                                <img src={this.getImage(location.type)} alt={location.name} width={18} height={18} />
                                                    <Typography style={{fontWeight:700,fontSize:'14px',color:'#0F172A'}}>{location.contact_name || location.name}</Typography>
                                                </NameContainer>
                                                <AddressContainer  style={{fontWeight:400,fontSize:'14px',color:'#0F172A'}}>
                                                    {location.address}
                                                </AddressContainer>
                                            </InfoContainer>

                                            {/* Three-dot Menu Button */}
                                            <IconButton onClick={(e) => this.handleOpenPopover(e, location.id)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        </StyledListItem>
                                    ))}
                                </List>
                            </DialogContent>

                            {/* Popover for more options */}
                            <Popover
                                open={Boolean(this.state.anchorEl) && this.state.selectedId !== null}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClosePopover}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: this.getPopoverHorizontalPosition(window),
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal:  this.getPopoverPosition(window),
                                }}
                                sx={{
                                    fontFamily: "'Inter', sans-serif !important",
                                    marginLeft:  this.getPopoverMargin(window), // Remove margin for small screens
                                    maxWidth: "80vw", // Move popover slightly more to the right
                                }}
                            >
                                <App>
                                    <StyledMenuItem>
                                        <ListItemIcon>
                                        <img src={edit.default} alt={location.name} width={16} height={16} />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit" style={{ fontFamily: "'Inter', sans-serif !important",fontWeight:400,fontSize:'14px',color:'#0F172A'}}/>
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={this.handleDeletePopUP}>
                                        <ListItemIcon>
                                        <img src={deleteImg.default} alt={location.name} width={16} height={16} />
                                        </ListItemIcon>
                                        <ListItemText primary="Delete" style={{ fontFamily: "'Inter', sans-serif !important",fontWeight:400,fontSize:'14px',color:'#0F172A'}}/>
                                    </StyledMenuItem>
                                </App>
                            </Popover>
                        </StyledDialog2> 
                        <StyledDialog3 open={this.state.deletePopUp} onClose={() => { this.onCloseDeletePopup() }}>
                    <StyledDialogContent2>
                        <img src={danger.default} alt="Delete Icon" style={{width:'24px',height:'24px'}} />
                        <Typography variant="h6" gutterBottom style={{ paddingTop: '20px' }}>
                            Are you sure you want to delete this Location?
                        </Typography>
                    </StyledDialogContent2>
                    <ActionsContainer>
                        <StyledCancelButton onClick={() => { this.onCloseDeletePopup() }} data-test-id="CancelButton">No</StyledCancelButton>
                        <StyledDeleteButton onClick={this.deleteLocation}>Yes</StyledDeleteButton>
                    </ActionsContainer>
                </StyledDialog3>
                    </div>
                    <Box style={webStyle.buttonbox} onClick={this.moveTonextStep} data-test-id="Movetochoosedate">
                        <Box style={webStyle.buttonchild}>
                            <Button style={webStyle.buttontext}>{configJSON.continue}</Button>
                        </Box>

                    </Box>
                </div>
                <div className="map-background-div">
                    <RideOptions navigation={this.props.navigation} id={""}
                        opensearch={this.props.opensearch}
                        setactive={this.props.setactive}
                        planride={this.props.planride} />
                </div>

                <Snackbar
                    open={this.state.isToastOpen}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert severity="error" onClose={this.handleClose}
                        sx={{ backgroundColor: "red", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
                        {configJSON.error}
                    </MuiAlert>
                </Snackbar>
                <Snackbar
          open={this.state.isToastOpen1}
          autoHideDuration={3000}
          onClose={this.handleToastClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}  // Position: top-right
        >
          <MuiAlert onClose={this.handleToastClose} severity="success"
            sx={{ backgroundColor: '#4caf50', color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
            {configJSON.favLocationdeleted}
          </MuiAlert>
        </Snackbar>
            </div>


            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledDialog3 = styled(Dialog)({
    "& .MuiPaper-root": {
      borderRadius: "16px", // Apply border radius to the dialog
    },
  });
const StyledDeleteButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#DC2626",
    color: "#FFFFFF",
    borderRadius: "10px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "100px",
    textTransform: "none",
  
    "&:hover": {
      backgroundColor: "#B91C1C",
    },
  
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "6px 12px",
      minWidth: "80px",
    },
  }));
const ActionsContainer = styled(DialogActions)`
    justify-content: center;
    padding-bottom: 16px;
    padding-Top:20px;
  `;
  const StyledCancelButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#E7E5E4",
    color: "#64748B",
    borderRadius: "10px",
    padding: "8px 16px",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "100px",
    marginRight:'20px',
    textTransform: "none", // Remove uppercase text
  
    "&:hover": {
      backgroundColor: "#D6D3D1",
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        padding: "6px 12px",
        minWidth: "80px",
      },
    }));
const StyledListItem = styled(ListItem)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
  });
  
  const App = styled(Box)({
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    minWidth: "140px",
    fontFamily: "'Inter', sans-serif !important",
  });
  
  const StyledMenuItem = styled(MenuItem)({
    display: "flex",
    alignItems: "center",
    gap: "5px", // Increased gap between icon and text
    padding: "7px",
    fontFamily: "'Inter', sans-serif !important",
  });
  
  const InfoContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: 1, // Ensures proper alignment
  });
  
  const NameContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "8px", // Space between icon and name
  });
  
  const AddressContainer = styled(Typography)({
    marginLeft: "25px", // Push address to align with name
  });
  
const StyledDialog2 = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
    //   padding: "10px",
    fontFamily: "'Inter', sans-serif !important",
      width: "90%",
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
  }));
const webStyle = {
    gridCSS: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    OutLineCHeckbox: {
        width: '30px',
        height: '30px',
        borderRadius: '7px', // Adding border radius

    },
    checkBox: {
        width: '30px',
        height: '30px',
        borderRadius: '28.05px',
        backgroundColor: '#fff',

        color: '#87C122',
    },

    buttonbox: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        height: "60.4px",
        alignItems: "center",
        paddingTop: "80px"
    },

    buttonchild: {
        width: "65%",
        background: "#1D6FB8",
        textAlign: "center" as "center",
        color: "#ECFDF5",
        borderRadius: "8px"
    },

    buttontext: {
        color: "#ECFDF5",
        textTransform: "capitalize" as "capitalize",
        fontSize: "20px",
        fontWeight: 400
    },

    oncontinuebox: {
        width: "50%",
        background: "#1D6FB8",
        textAlign: "center" as 'center',
        color: "#ECFDF5",
        borderRadius: "8px"
    },

    locationinputfilletext: {
        color: "#334155",
        fontSize: "16px",
        fontWeight: 600,
    },

    locationinputplaceholder: {
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 400,
    },

    optiontext: {
        color: "#334155",
        fontSize: "16px",
        fontWeight: 700,
        cursor: "pointer"
    },

    errortext: {
        color: 'red',
        fontSize: "15px",
        fontWeight: 400
    }

}
// Customizable Area End