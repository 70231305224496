Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";
exports.userProfileEndPoint="bx_block_profile/profile/show"
exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";
exports.userProfileEndPoint = "bx_block_profile/profile/show";
exports.editProfile = 'Edit Profile';
exports.tripHistory = 'Trip History';
exports.favLocation = 'Favorite Locations';
exports.notifications = 'Notifications';
exports.payments = 'Payments';
exports.helpCenter = 'Help Center';
exports.settings = 'Settings';
exports.name = "Cameron Rain";
exports.color1 = '#87C122';
exports.color2 = '#000000';
exports.testsection1 = 'Test Section  (Edit Profile)';
exports.testsection2 = 'Test Section  (Trip History)';
exports.testsection3 = 'Test Section  (Favorite Locations)';
exports.testsection4 = 'Test Section  (Notifications)';
exports.testsection5 = 'Test Section  (Help Center)';
exports.testpayment =  "Test Section (Payment)";
exports.camera =  'camera';
exports.block =  "block";
exports.none =  "none";
exports.px40 =  "40px";
exports.px0 =  "0px";
// Customizable Area End