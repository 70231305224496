import React from 'react';
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import {choseedate,appointment,map}  from "./assets";
import {
  Typography,
  Dialog,
  Button,
  Box,
  styled,DialogContent,DialogActions
} from "@mui/material";
import "../../email-account-registration/src/EmailAccountRegistratin.css"
import { avatard, star, taskcard } from "./assets";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  buttonTabs = () => {
    return (
      <>
       <Tabbox>
        <Buttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="choosedateid">
              <img src={choseedate.default} style={webstyle.choosedateimage} /> All
            </Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '2' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="planyourrideid">Scheduled</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '3' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="upcomingrideid">Completed</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '4' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("4")} data-test-id="upcomingrideid">Cancelled</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '5' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("5")} data-test-id="upcomingrideid">Accepted</Button>
          </Singlebuttonbox>
        </Buttonbox>
        <ContentContainer>
          <ContentBox>
            {this.screentab()}
          </ContentBox>
        </ContentContainer>
      </Tabbox>
      </>

    )
  }

  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
       return <div className='firstdiv'>{this.renderTable("1")}</div>

      case '2':
        return <div className='firstdiv'>{this.renderTable("2")}</div>
      case '3':
        return <div className='firstdiv'>{this.renderTable("3")}</div>
        case '4':
          return <div className='firstdiv'>{this.renderTable("4")}</div>
          case '5':
          return <div className='firstdiv'>{this.renderTable("5")}</div>
      default:
        return <div className='firstdiv'>{this.renderTable("5")}</div>
    }
  }

  renderTable = (currenttab:string) => {   
    let sampleData = this.getSampleData(currenttab); 
    return (

      <div style={{padding:'20px',backgroundColor:'white',borderRadius:'20px',}}>
    <div className="table-container">
  <table className="custom-table">
    <thead>
      <tr className="header-row">
        <th>Job ID</th>
        <th>Pickup Location</th>
        <th>Destination</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      <br />
    </thead>
    <tbody>
              {sampleData.length > 0 ? (
                sampleData.map((row: any) => (
                  <React.Fragment key={row.job_id}>
                    <tr className="content-row" onClick={() => this.handlePopup(row)}>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleJobId(e, row);
                        }}
                        style={{cursor:'pointer'}}
                        className="hover-box"
                      >
                        {this.getAttribute(row, 'job_id')}</td>
                      <td>{this.getAttribute(row, 'start')}</td>
                      <td>{this.getAttribute(row, 'destination')}</td>
                      <td>
                        <span style={{
                          backgroundColor: this.getCellBackgroudColor(this.getAttribute(row, 'status')),
                          padding: '8px',
                          borderRadius: '20px',
                          fontWeight: '700',
                          color: this.getCellTextColor(this.getAttribute(row, 'status')),
                          fontSize: '12px'
                        }}>
                          {this.toUpperCase(this.getAttribute(row, 'status'))}
                        </span>
                      </td>
                      <td>
                        <Button
                          style={{
                            backgroundColor: '#1D6FB8',
                            padding: '8px',
                            borderRadius: '13px',
                            fontWeight: '400',
                            color: 'white',
                            width: '100%',
                            display: 'inline-block',
                            cursor: 'pointer',
                          }}
                          onClick={() => this.handleActionClick(this.getButtonText(this.getAttribute(row, 'status')))}
                          data-test-id="AddNote"
                        >
                          {this.getButtonText(this.getAttribute(row, 'status'))}
                        </Button>
                      </td>
                    </tr>
                    <br />
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan={5} style={{ textAlign: 'center', padding: '20px', fontSize: '16px', fontWeight: 700 }}>
                    No rides available
                  </td>
                </tr>
              )}
    </tbody>
  </table>
</div>
    </div>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <div style={webstyle.AppointmentOuter} className='outerTabs'>
        <NavigationMenu navigation={this.props.navigation} id={""} index={1}/>
        <Box style={{ paddingTop: "40px", display: 'flex', justifyContent: 'center' ,marginBottom:'30px'}}>
          <OuterContainer>
            {this.buttonTabs()}
            <ImageBox>
            <ResponsiveImage src={appointment.default} alt="Right side image" />
            </ImageBox>
          </OuterContainer>
         
        </Box>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', marginBottom: '20px'
        }}>

       <ResponsiveImageMap src={map.default} alt="Right side image" />  
       <Dialog
          open={this.state.popupOpen}
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius: '9.1px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              minWidth: '24%',
              margin: 'auto',
              borderLeft: 'solid 4px #87C122',
            },
          }}
          onClose={this.closeDialog}
        >
          <DialogContent>
            <CardContainer>
              <Header>
                <Avatar>
                  <img src={avatard.default} alt="" />
                </Avatar>
                <Box>
                  <Name>Jane Deo</Name>
                  <Box display="flex" alignItems="center">
                    <Rating><img src={star.default} alt="" /></Rating>
                    <RatingNumber>4.8</RatingNumber>
                    <Price>$25</Price>
                  </Box>
                </Box>
              </Header>
              <Details>
                <Box display='flex'> <Box> <img src={taskcard.default} alt="" style={{ margin: '0', width: '45px', height: '125px' }} /></Box>
                  <Box> <LocationWrapper style={{marginBottom:'40px'}}>
                    <Box>
                      <Location><strong>Pick Up From:</strong></Location>
                      <Address>{this.state.selectedRide?.attributes?.start || 'N/A'}</Address>
                    </Box>
                    <DateTime>
                    <Address>{this.formatDate(this.getAttributeValue('date'))}</Address>
                    <Address>{this.formatTime(this.getAttributeValue('time'))}</Address>
                    </DateTime>
                  </LocationWrapper>

                    <LocationWrapper>
                      <Box>
                        <Location><strong>Drop Off At:</strong></Location>
                        <Address>{this.state.selectedRide?.attributes?.destination || 'N/A'}</Address>
                      </Box>
                      <DateTime>
                      <Address>{this.formatDate(this.getAttributeValue('date'))}</Address>
                      <Address>{this.formatTime(this.getAttributeValue('time'))}</Address>
                      </DateTime>
                    </LocationWrapper>
                  </Box>
                </Box>
              </Details>
            </CardContainer>
          </DialogContent>
          <StyledDialogActions>
            <StyledButton variant="outlined" color="error" onClick={this.RejectRide}>
              Reject
            </StyledButton>
            <StyledButton variant="contained" color="primary" onClick={this.AcceptRide}>
              Accept
            </StyledButton>
          </StyledDialogActions>

        </Dialog>
        <Snackbar
                    open={this.state.isToastOpen}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert severity={this.state.rideRejected ? "error" : "success"} onClose={this.handleClose}
                        sx={{ backgroundColor: this.state.rideRejected ? "red" : "green ", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
                       {this.state.rideRejected ? configJSON.Rejected : configJSON.Accepted}
                    </MuiAlert>
                </Snackbar>      
       </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '12px',
  borderBottom: '1px solid #E2E8F0'
}));

const Avatar = styled(Box)(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: '#ccc',
  marginRight: '12px',
}));

const Name = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '18px',
  marginBottom: '8px',
}));

const Rating = styled(Typography)(({ theme }) => ({
  marginRight: '4px',
}));

const RatingNumber = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#000', // Black color for rating number
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  marginLeft: 'auto',
  color: '#000',
}));

const Details = styled(Box)(({ theme }) => ({
  marginTop: '16px',
  borderBottom: '1px solid #E2E8F0',
}));

const LocationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start', // Aligns content at the top
  marginBottom: '8px',

}));
const Location = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: '700',
  marginBottom: '4px', // Adds spacing between location title and address
}));

const Address = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: '#94A3B8',
}));

const DateTime = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  fontSize: '8px',
  color: '#94A3B8',
  lineHeight: '1',
  marginLeft: '60px'
}));

const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'flex-start',
  padding: '16px',
  paddingTop:'0px',
  gap: '8px'
});

const StyledButton = styled(Button)({
  height: '46px',
  width: '155px',
  borderRadius: '9px',
textTransform:'none',
fontSize:'18px'
});
const ResponsiveImageMap = styled('img')(({ theme }) => ({
  width: '95%',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '90%', // 90% width for small screens
  },
  [theme.breakpoints.up('md')]: {
    width: '95%', // 95% width for medium and larger screens
  },
}));

const ResponsiveImage = styled('img')(({ theme }) => ({
  width: '80%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '60%',  // Adjust width on medium screens and below
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',  // Full width on small screens
    height:'100%'
  },
}));
const Singlebuttonbox = styled(Box)({
  width: "130px",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
});
const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column", // Arrange content in a column
  marginTop: "20px", // Space between the tab bar and the content
});
const OuterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column', 
    justifyContent: 'space-evenly',
    height:'auto'
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  flex: '0 0 30%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '20px',
  overflow: 'hidden',
  maxHeight: '100%',
  [theme.breakpoints.down('sm')]: {
    flex: '0 0 100%', // Take full width on small screens
    marginLeft: '0px', // Remove left margin
    maxHeight: '25%',

  },
}));

const ContentBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    padding: '10px', // Adjust padding for small screens
  },
}));

const Tabbox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column", 
  [theme.breakpoints.down('sm')]: {
    width: "100%", // Full width on small screens
  },
}));

const Buttonbox = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "65px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
  [theme.breakpoints.down('sm')]: {
    height: "50px", // Adjust height for small screens
    paddingLeft: "5px", 
    paddingRight: "5px",
  },
}));


const webstyle = {
  AppointmentOuter:{  margin: '0px', padding:'0px', width: '100vw', height: '100vh', },
  activeButoon: {
    width: "100%",
    background: "#ECFDF5",
    borderRadius: "50px",
    border:'1px solid #87C122',
    color: "#0F172A",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#475569",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
