// Customizable Area Start
import React from "react";
import {  styled,
    Typography,
    Box,
    TextField,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Checkbox,
    IconButton,
    InputAdornment,
    LinearProgress,
    FormLabel,
    Modal,
    CircularProgress } from "@mui/material";
import { Logo, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import SignUpDriverController, { Props } from "./SignUpDriverController";
const configJSON = require("./config");
import { Visibility, VisibilityOff } from '@mui/icons-material';
// Customizable Area End

// Customizable Area Start
const StyledBox = styled(Box)({
    width: "100vw",
    height: "100vh",
    display: "flex",
    '@media (max-width: 960px)': {
      flexDirection: "column",
      height: "auto",
    }
  });
  
  const LeftHalf = styled("div")({
    width: "50%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    "@media (max-width: 960px)": {
      width: "100%",
      height: "100%",
    }
  });
  
  const FormContainer = styled("div")({
    width: "100%",
    height: "100%",
    maxWidth: "440px",
    overflow: "scroll",
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: "none",
    "@media (max-width: 960px)": {
      width: "80%",
      marginTop: "20px"
    }
  });
  
  const RadioStepOne = styled(Radio)({
    '&.Mui-checked': {
      color: '#87C122',
    },
  });
  
  const TypographyCreateYour = styled(Typography)({
    color: '#1D6FB8',
    fontWeight:400 ,
    width:"100%",
    maxWidth:"440px",
    fontFamily:"'Inter', sans-serif",
    fontSize: "30px",
    marginTop: "10px",
    textAlign: "center",
    "@media (max-width: 960px)": {
      fontSize: "20px",
    },
    "@media (max-width: 440px)": {
      fontSize: "23px",
    }
  });
  
  const CheckboxStepOne = styled(Checkbox)({
    borderRadius: '50px'
  });
  
  const InvalidEmail = styled(Typography)({
    color: "#DC2626",
    marginTop: "30px"
  })
  
  const TypographyIagress = styled(Typography)({
    fontSize: '14px',
    width: '386px',
    fontFamily:"'Inter', sans-serif",
    
    "@media (max-width: 960px)": {
      width: '100%',
    }
  });
  
  const TypographyStepOne = styled(Typography)({
    fontWeight: 500,
    fontSize: '18px',
    color: '#1D6FB8',
    marginBottom: '8px',
  });
  
  const ProgressBarContainer = styled('div')({
    width: '100%',
    margin: '10px 0',
  });
  
  const CustomLinearProgress = styled(LinearProgress)({
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#E0E0E0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#87C122',
    },
  });
  
  const ButtonOne = styled(Button)(({ theme }) => ({
    marginTop: '24px',
    height: '60px',
    color: '#FFFFF',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    textTransform: 'none',
    fontSize:"20px",
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));
  
  const BottomText = styled("div")({
    textAlign: "center",
    fontWeight: 400,
    color: "#000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  });
  
  const TypographyBackTo = styled(Typography)({
    color: "#000000",
  });
  
  const ButtonBackto = styled(Typography)({
    color: '#1D6FB8',
    marginLeft: "5px"
  });
  
  const InputWrapper = styled(Box)({
    width: '100%',
    margin: '30px 0px',
  });
  
  const CustomLabel = styled(Typography)({
    backgroundColor: 'white',
    fontSize: '14px',
    color: '#334155',
    fontWeight: 700,
    marginBottom: '5px',
    fontFamily:"'Inter', sans-serif"
  });
  
  const TextFieldCustom = styled(TextField)({
    width: '100%',
    height: "40px",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
  });
  const TextFieldCustomDriver2 = styled(TextField)({
    width: '100%',
    height: "40px",
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
    },
  });
  const ValidationText = styled(Typography)({
    fontSize: "14px",
    color: "#000000",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    fontFamily:"'Inter', sans-serif",
  });
  
  const Circle = styled("span")({
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    fontSize: "12px",
    color: "white",
    fontWeight: "bold",
    marginRight: "8px",
  });
  
  const CircleGreen = styled(Circle)({
    backgroundColor: "#34D399",
    textAlign: "center"
  });
  
  const CircleRed = styled(Circle)({
    backgroundColor: "#DC2626",
    textAlign: "center"
  });
  
  const CheckboxValidationContainer = styled(Box)({
    marginBottom: "24px",
    padding: "10px",
    border: "1px solid #DC2626",
    borderRadius: "4px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    fontSize: "14px",
  });
  
  const MyFormLabel = styled(FormLabel)({
    color: '#334155',
    fontWeight: 600,
    letterSpacing: '0.5px',
    fontSize: '14px',
    marginBottom: '5px',
  });
  
  const SelectDialog = styled(Box)({
    background: '#ffffff',
  });
  
  const StyledTypography = styled(Typography)({
    padding: '12px',
    border: '1px solid #C0C0C0',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 960px)": {
      fontsize: "12px"
    }
  });
  
  const IconButtonArrow = styled(IconButton)({
    marginLeft: '110px',
  });
  
  const ButtonBackButton = styled(Button)(({ theme }) => ({
    margin: '15px 0px',
    height: '60px',
    color: '#1D6FB8',
    border: '1px solid #1D6FB8',
    width: '100%',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: '#FFFFFF',
    },
  }));
  
  const FormControlLabelCustom = styled(FormControlLabel)({
    marginTop: '24px'
  });
  
  const BoxWheel = styled(Box)({
    boxSizing: 'border-box',
    overflow: 'hidden',
    padding: '0px',
    margin: '0px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    backgroundColor: '#ffffff',
    borderRadius: '24.89px',
    "@media (max-width: 960px)": {
      width: "90%"
    }
  });
  
  const SelectDriveType = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'activeBTN',
  })<{ activeBTN: boolean; }>(({ activeBTN }) => ({
    display: 'flex',
    padding: '20px',
    boxSizing: 'border-box',
    margin: '0px',
    backgroundColor: activeBTN ? '#ECFDF5' : '#ffffff',
  }));
  
  const SelectBoxModal = styled(Box)({
    border: '2px solid #64748B',
    borderRadius: '50%',
    width: '23px',
    height: '23px',
    marginRight: '13px',
    marginLeft: '13px',
  });
  
  const SelectNameTypography = styled(Typography)({
    marginTop: '5px',
  });
  
  const ErrorBox = styled(Box)({
    width: '600px',
    overflow: 'hidden',
    backgroundColor: '#FEE2E2',
    padding: '25px',
    borderRadius: '24.89px',
    borderLeftStyle: 'solid',
    borderLeftColor: '#DC2626',
    borderLeftWidth: '7px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    margin: '0px',
    "@media (max-width: 960px)": {
      width: "90%",
      padding: '10px',
    }
  });
  
  const BoxCenter = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  });
  
  const ErrorImg = styled('img')({
    width: '289.73px',
    height: '214.93px',
    "@media (max-width: 960px)": {
      width: "90%",
      height: '180px',
    }
  })
  
  const TypographyOops = styled(Typography)({
    color: '#DC2626',
    fontSize: '24px',
    marginTop: '30px',
    "@media (max-width: 960px)": {
      fontSize: '16px',
      marginTop: '15px',
    }
  })
  
  const TypographyDouble = styled(Typography)({
    color: '#94A3B8',
    marginTop: '30px',
    fontSize: '24px',
    textAlign: 'center',
    "@media (max-width: 960px)": {
      fontSize: '16px',
      marginTop: '15px',
    }
  })
  
  const ButtonReturn = styled(Box)({
    background: '#DC2626',
    ':hover': {
      backgroundColor: '#DC2626',
    },
    color: '#FFFFFF',
    width: '50%',
    height: '80px',
    borderRadius: '16px',
    marginTop: '45px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 960px)": {
      width: '80%',
      height: '40px',
      marginTop: '20px',
    }
  })
  
  const TypographyRetry = styled(Typography)({
    fontSize: '30px',
    marginTop: '5px',
    "@media (max-width: 960px)": {
      fontSize: '15px',
    }
  })
  
  const ImgRetry = styled('img')({
    width: '50px',
    height: '50px',
    margin: '0px 10px',
    "@media (max-width: 960px)": {
      width: '25px',
      height: '25px',
    }
  })
  
  const RightHalf = styled(Box)({
    width: "50%",
    height: "100%",
    textAlign: 'center',
    backgroundColor: "#ECFDF5",
    "@media (max-width: 960px)": {
      width: "100%",
      height: "100%",
    }
  })
  const MainImgage = styled('img')({
    width: '100%',
    height: '85%',
    "@media (max-width: 960px)": {
      width: '100%',
    }
  });
  
  const TextBox = styled(Box)({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  });
  
  const TypographyImgTextOne = styled(Typography)({
    color: '#1D6FB8',
    marginTop: '14px',
    fontWeight: 400,
    fontSize: '20px',
    "@media (max-width: 960px)": {
      fontSize: '15px',
    }
  });
  
  const TypographyImgTextTwo = styled(Typography)({
    color: '#1D6FB8',
    marginTop: '14px',
    fontWeight: 400,
    fontSize: '20px',
    "@media (max-width: 960px)": {
      fontSize: '15px',
    }
  })


const TermConditionBox = styled(Button)(({ theme }) => ({
  width:"100%",
  maxWidth: "440px",
  height: "68px",
  marginTop:"10px",
  backgroundColor: "#FEE2E2",
  color: "#DC2626",
  display: "flex",
  textAlign:"left",
  textTransform:"none",
  gap:"8px",
  padding: "12px 16px 12px 16px",
  borderLeft: "4px solid #DC2626",
  borderRadius: 4,
  fontSize: "14px",
  fontWeight:400,
  '&:hover':{
    backgroundColor: "#FEE2E2",
  }

}));
// Customizable Area End

export default class SignUpDriver extends SignUpDriverController {

    render() {
        // Customizable Area Start
        return (
            <StyledBox>
        <LeftHalf>
          <FormContainer>
            <TypographyCreateYour>
              {configJSON.createYourEryde}
            </TypographyCreateYour>

            <ProgressBarContainer>
              <TypographyStepOne
                variant="body2"
                data-testId={"progress-step"}
              >
                Step 1 of 4
              </TypographyStepOne>
              <CustomLinearProgress
                variant="determinate"
                value={25}
                data-testId={"progress-bar"}
              />
            </ProgressBarContainer>

            {this.state.showApiError && (
              <CheckboxValidationContainer>
                <Typography data-testId={"terms-error"}>{this.state.apiErrorMessage}</Typography>
              </CheckboxValidationContainer>
            )}

<Box data-testId={"continueStepOneId"} component={"form"} onSubmit={this.handleSubmitDriverAccount}>
        <RadioGroup
          row
          value={this.state.role}
          onChange={this.handleRoleChangeDriver}
          data-testId={"radioButtonId"}
        >
          <FormControlLabel
            value="rider"
            control={
              <RadioStepOne />
            }
            label="Rider"
          />
          <FormControlLabel
            value="driver"
            control={
              <RadioStepOne />
            }
            label="Driver"
          />
        </RadioGroup>

        {
          !this.state.agreeToTerms && 
        <TermConditionBox>
          You have to agree with Privacy Policy and Terms and Conditions to Sign up
        </TermConditionBox>
        }

        <InputWrapper>
          <CustomLabel>{configJSON.fname}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            placeholder="Type here your First Name..."
            fullWidth
            required
            name={"firstName"}
            value={this.state.firstName}
            onChange={this.handleChangeForFNameDriver}
            data-testId={"firstNameId"}
            error={this.state.FNameError}
          />
          {this.state.FNameError && (
            <InvalidEmail>
              Name must be between 2 and 30 characters long.
            </InvalidEmail>
          )}
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.lname}</CustomLabel>
          <TextFieldCustom
            variant="outlined"
            placeholder="Type here your Last Name..."
            fullWidth
            required
            name={"lastName"}
            value={this.state.lastName}
            onChange={this.handleChangeForLNameDriver}
            error={this.state.LNameError}
          />
            {this.state.LNameError && (
           <InvalidEmail>
              Last Name must be between 2 and 30 characters long.
            </InvalidEmail>
            )}
        </InputWrapper>

        <InputWrapper>
          <CustomLabel>{configJSON.email}</CustomLabel>
          <TextFieldCustom
            data-testId={"emailId"}
            variant="outlined"
            fullWidth
            required
            placeholder="Type here your email address..."
            type="email"
            name={"email"}
            value={this.state.email}
            onChange={this.handleEmailChangeDriver}
            error={this.state.emailError}
          />

          {this.state.emailError && (
            <InvalidEmail>
              {configJSON.invalidEmail}
            </InvalidEmail>
          )}

        </InputWrapper>

        <InputWrapper>
          <CustomLabel>Password</CustomLabel>
          <TextFieldCustomDriver2
            data-testId={"passwordId"}
            type={this.state.showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            required
            placeholder="Type here your password..."
            name={"password"}
            value={this.state.password}
            onChange={this.handleDriverPasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPasswordDriver}
                    edge="end"
                  >
                    {this.state.showPassword ? (
                     <Visibility />
                    ) : (
                      <VisibilityOff /> 
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </InputWrapper>

        {this.state.password && !(
    this.state.passwordCriteria.hasUpperCase &&
    this.state.passwordCriteria.hasLowerCase &&
    this.state.passwordCriteria.hasNumber &&
    this.state.passwordCriteria.hasSpecialChar &&
    this.state.passwordCriteria.isValidLength
) && (
  <>
          <ValidationText>
          {this.state.passwordCriteria.hasUpperCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasUpperCase}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasLowerCase ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasLowerCase}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasNumber ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasNumber}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.hasSpecialChar ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.hasSpecialChar}
        </ValidationText>
        <ValidationText>
          {this.state.passwordCriteria.isValidLength ? <CircleGreen>✓</CircleGreen> : <CircleRed>-</CircleRed>}
          {configJSON.minimumChar}
        </ValidationText>
        </>
      )}

        <FormControlLabelCustom
          data-testId={"checkBoxId"}
          control={
            <CheckboxStepOne
              checked={this.state.agreeToTerms}
              onChange={this.handleCheckboxChangeDriver}
            />
          }
          label={
            <TypographyIagress
              variant="body2"
              color={!this.state.agreeToTerms ? '#DC2626' : 'inherit'}
              onClick={()=>this.navigateTermscondition("TermsConditions")}
            >
              <span>I agree with <span style={{textDecoration:"underline"}}>Privacy Policy and Terms and Conditions</span></span>
            </TypographyIagress>
          }
        />

        <ButtonOne
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3 }}
          disabled={!this.state.agreeToTerms}
          type="submit"
        >
          {this.state.btnLoader ? <CircularProgress /> : configJSON.continue}
        </ButtonOne>

        <BottomText>
          <TypographyBackTo>
            {configJSON.alreadyHavAccount}
          </TypographyBackTo>
          <ButtonBackto data-testId={"loginBtnId"} onClick={this.handleLoginDriver}>{configJSON.login}</ButtonBackto>
        </BottomText>
      </Box>

          </FormContainer>
        </LeftHalf>
        <RightHalf>
          <MainImgage src={Logo.default} alt="right-main" />

          <TextBox>
            <TypographyImgTextOne>{configJSON.DriverFirsttext}</TypographyImgTextOne>
            <TypographyImgTextTwo>{configJSON.nonMedical}</TypographyImgTextTwo>
          </TextBox>
        </RightHalf>

      </StyledBox>
        );
        // Customizable Area End
    }
}
