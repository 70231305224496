import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { removeStorageData } from "../../../framework/src/Utilities";
import creditCardType from "credit-card-type";
import * as Yup from 'yup'
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  redirectedit?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedSection: string;
  isSidebarOpen: boolean;
  openmodal: boolean;
  usertype: string
  pagetoshow: string
  cardnumber: string
  expirrydate: string
  prevpass: string
  oldpassunmatch: boolean
  cardHolderName: string
  isToastOpen: boolean
  cardList: any
  userdetails: any
  isValid: any
  cardType: any
  cardHolderNameError: boolean
  expirrydateError: boolean
  paypalidError: boolean
  paypalid: string
  succesMessage: string
  prevpassvisible: boolean
  newpassvisibile: boolean
  confirmpassvisibile: boolean
  isInputFocused:boolean
  isCardinputfocused:boolean,
  selectedState: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePassowrdApiCallId: any
  addCardApiCallId: any
  getCardlistApiCallId: any
  userprofiledetailsApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.changePassowrdApiCallId = ""
    this.addCardApiCallId = ""
    this.getCardlistApiCallId = ""
    this.userprofiledetailsApiCallId = ""
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedSection: 'Settings',
      isSidebarOpen: true,
      openmodal: false,
      usertype: "",
      pagetoshow: "",
      cardnumber: "",
      expirrydate: "",
      prevpass: "",
      oldpassunmatch: false,
      cardHolderName: "",
      isToastOpen: false,
      cardList: [],
      userdetails: {},
      isValid: null,
      cardType: '',
      cardHolderNameError: false,
      expirrydateError: false,
      paypalidError: false,
      paypalid: "",
      succesMessage: "",
      prevpassvisible: false,
      newpassvisibile: false,
      confirmpassvisibile: false,
      isInputFocused:false,
      isCardinputfocused:false,
      selectedState: " "
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.getCardlistApiCallId) {
        this.setState({ cardList: responseJson })
      }

      if (apiRequestCallId === this.addCardApiCallId) {
        this.setState({ cardHolderName: "", paypalid: "", expirrydate: "", cardnumber: "", pagetoshow: 'carddetails', isValid: null, succesMessage: "Card Added succesfully", isToastOpen: true })
        this.Getcardlist()
      }
      if (apiRequestCallId === this.userprofiledetailsApiCallId) {
        this.setState({ userdetails: responseJson?.data })
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userlogged = localStorage.getItem("role_id");
    this.getUserprofiledetails()
    if (userlogged) {
      this.setState({ usertype: userlogged })
    }
    // Customizable Area End
  }
  handleSectionChange = (section: string) => {
    const isMobile = window.innerWidth <= 960;
    this.setState({ selectedSection: section, isSidebarOpen: isMobile ? false : this.state.isSidebarOpen });
  };

  handleLogout = async () => {
    localStorage.removeItem("rideid")
    localStorage.removeItem("pagetoshow")
    localStorage.removeItem("redirectfrom")
    await removeStorageData("role_id");
    await removeStorageData("token");
    await removeStorageData("userId");
    await removeStorageData("role")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openCardlistpage = () => {
    this.setState({ pagetoshow: "carddetails" })
    this.Getcardlist()
  }


  handletoastclose = () => {
    this.setState({ isToastOpen: false })
  }
  
  backtoCarddetails = () =>{
    
    this.setState({ pagetoshow: "carddetails",
      cardHolderName:"",
      cardnumber:"",
      expirrydate:"",
      paypalid:"",
      paypalidError:false,
      isValid:null,
      cardHolderNameError:false,
      expirrydateError:false })
  }

  addCreditcard = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const formData = new FormData();
      formData.append('card_number', this.state.cardnumber);
      formData.append('expiry_date', this.state.expirrydate);
      formData.append("cardholder_name", this.state.cardHolderName)
      formData.append('credit_card_type', this.state.cardType)
      formData.append("paypal_id", this.state.paypalid)
      const header = {
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCardApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/credit_cards`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postmethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  Getcardlist = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getCardlistApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_settings/credit_cards`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getmethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleToggleSidebar = () => {
    this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
  };
  handleLogoutmodal = () => {
    this.setState({ openmodal: true })
  };

  handleClosemodal = () => {
    this.setState({ openmodal: false })
  };

  redirectUser = (route: string) => {
    localStorage.setItem("redirectfrom", "settings")
    if (this.state.usertype == "driver" || route == "TermsConditions") {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      this.setState({ pagetoshow: "contactus" })
    }
  }
  
  handleNavigation = (route: string) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }


  getUserprofiledetails = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.userprofiledetailsApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_profile/profile/show`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpGetMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };

  // Customizable Area End
}
