import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  redirectedit?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  passwordCriteria: {
    hasUpperCase: boolean,
    hasLowerCase: boolean,
    hasNumber: boolean,
    isValidLength: boolean
    hasSpecialChar: boolean
  };
  password: string
  passwordMatcherror: boolean
  confirmPassword: string
  usertype: string
  pagetoshow: string
  prevpass: string
  oldpassunmatch: boolean
  isToastOpen: boolean
  succesMessage: string
  prevpassvisible: boolean
  newpassvisibile: boolean
  confirmpassvisibile: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePassowrdApiCallId: any
  addCardApiCallId: any
  userprofiledetailsApiCallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.changePassowrdApiCallId = ""
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      passwordCriteria: {
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        isValidLength: false,
        hasSpecialChar: false
      },
      password: "",
      passwordMatcherror: false,
      confirmPassword: "",
      usertype: "",
      pagetoshow: "",
      prevpass: "",
      oldpassunmatch: false,
      isToastOpen: false,
      succesMessage: "",
      prevpassvisible: false,
      newpassvisibile: false,
      confirmpassvisibile: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);
      
      if (responseJson && responseJson.message) {
        if (apiRequestCallId === this.changePassowrdApiCallId) {
          this.setState({ pagetoshow: "", isToastOpen: true, prevpass: "", confirmPassword: "", password: "", succesMessage: "Password Changed Successfully" })
        }

      }
      else if (responseJson && responseJson.error) {
        this.setState({ oldpassunmatch: true })
      }
    }
    // Customizable Area End
  }



  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userlogged = localStorage.getItem("role_id");
    if (userlogged) {
      this.setState({ usertype: userlogged })
    }
    // Customizable Area End
  }

  changePassword = () => {
    const token = localStorage.getItem("token");
    if (token) {
      let data = {
        data: {
          old_password: this.state.prevpass,
          new_password: this.state.password,
          confirm_new_password: this.state.confirmPassword
        },
      };

      const header = {
        "Content-Type": configJSON.exampleApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.changePassowrdApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_settings/settings/reset_password`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchAPiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

  };

  redirectUser = (route: string) => {
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
  }

  handleDriverPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;

    let passwordCriteria = {
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasNumber: /\d/.test(password),
      isValidLength: password.length >= 8,
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };

    this.setState({
      password,
      passwordCriteria,
    });
  };

  handleConfirmpassowrd = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmPassword: event.target.value, passwordMatcherror: false })
  };

  HandleSavepassword = () => {
    const { password, confirmPassword, passwordCriteria } = this.state;
    const isPasswordValid = Object.values(passwordCriteria).every(Boolean);
    const doPasswordsMatch = password === confirmPassword;
    if (isPasswordValid && doPasswordsMatch) {
      this.changePassword()
    } else {
      this.setState({ passwordMatcherror: true })
    }
  }

  Handleprevpass = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ prevpass: event.target.value, oldpassunmatch: false })

  }
  // Customizable Area End
}
