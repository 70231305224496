// Customizable Area Start
import React from "react";
import UserProfileDriverController, { Props } from "./UserProfileDriverController.web";
import {
    Box,
    styled,
    Button,
    TextField,
    FormLabel,
    FormControl,
    DialogContent,
    Dialog,
    Typography,
    Modal
} from '@mui/material';
import { arrowdown, photoDeleteIcon, photoUploadIcon, uploadimage } from "./assets";
import { configJSON } from "./UserProfileBasicController";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Customizable Area End

// Customizable Area Start
const DocumentUpdatecontainer = styled(Box)({
    minHeight: "100vh",
    backgroundColor: "#ECFDF5",
    "@media (max-width: 960px)": {
        minHeight: "auto",
    },
});

const StyledImageUpload = styled("img")({
    height: "24px",
    width: "24px",

});

const StyledImageDelete = styled("img")({
    height: "34px",
    width: "34px",
});

const UploadText = styled(Typography)({
    fontSize: "20px",
    marginLeft: "10px",
    textTransform: 'lowercase',
    cursor:"pointer",
    '&::first-letter': {
        textTransform: 'uppercase',
    },
});
const Uploadbutton = styled(Box)({
    width: "80%",
    display: "flex",
    padding: '15px 20px',
    margin: '5px auto',
    fontSize: "43px",
    borderRadius: "10px",
    fontWeight: 600,
    alignItems:"center",
    color: "#000000",
    ":hover": {
        backgroundColor: "#ECFDF5"
    }
});
const Ulpoadbox = styled(Box)({
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "none",
    height: "180px",
    display: "flex",
})

const Modalcontainer = styled(Box)({
    boxSizing: "border-box",
    overflow: "hidden",
    padding: "0px",
    margin: "0px",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    backgroundColor: "#ffffff",
    borderRadius: '24.89px',
});

const FormContainer = styled(Box)({
    "& .css-1hf16px-MuiFormControl-root-MuiTextField-root": {
        border: "none"
    },
    margin: "0",
    width: "95%",
    padding: "10px",
    "@media (max-width: 960px)": {
        width: "100%",
    }
});

const Input = styled(TextField)({
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        backgroundColor: "#ffffff",
    },
    '& .MuiOutlinedInput-input': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '60%',
    },
});

const MyFormLabel = styled(FormLabel)({
    color: '#334155',
    fontWeight: 700,
    fontSize: "14px",
    marginBottom: "5px",
    fontFamily: "Inter",
    lineHeight: "22px"
});

const Drivereditbuttobox = styled(Box)({
    width: "70%",
    display: "flex",
    flexDirection: "column" as "column",
    gap: '10px',
    alignItems: "center",
    "@media (max-width: 960px)": {
        width: "100%",
    }
});

const Saveeditbutton = styled(Box)({
    background: "#1D6FB8",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "56px",
    color: "black",
    width: "50%",
    display: "flex",
    "@media (max-width: 600px)": {
        width: "90%",
    }
});

const Nexteditbutton = styled(Box)({
    background: "",
    color: "black",
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid  #1D6FB8",
    borderRadius: "8px",
    height: "56px",
    "@media (max-width: 600px)": {
        width: "90%",
    }
});

const Autoinsaureneuploadimage = styled("img")({
    height: "20px",
    width: "20px",
    position: "absolute" as 'absolute',
    right: "9px",
    top: "44px",
    "@media (max-width: 461px)": {
        top: "47px"
    },
    "@media (max-width: 421px)": {
        top: "64px"
    }
});

const Drivinglicenseuploadimage = styled("img")({
    height: "20px",
    position: "absolute" as 'absolute',
    right: "9px",
    width: "20px",
    top: "44px",
    "@media (max-width: 329px)": {
        top: "67px"
    }
});


const CheckboxCheckmark = styled(Box)({
    content: '"✓"',
    position: "absolute" as "absolute",
    top: "10px",
    left: "3px",
    fontSize: "40.21px",
    cursor: "pointer",
    color: "white",
})


const CheckboxCheckmarkvehicletype = styled(Box)({
    content: '"✓"',
    fontSize: "40.3px",
    cursor: "pointer",
    color: "white",
    position: "absolute" as "absolute",
    top: "8px",
    left: "3px",
})

const Optionlabel2 = styled("label")({
    color: '#3C3E49',
    borderRadius: '8px',
    fontSize: '35px',
    fontWeight: 400,
    fontFamily: "Rubik",
    lineHeight: "69.47px",
    letterSpacing: "1%",
    '@media (max-width: 550px)': {
        fontSize: "30px"
    },
    '@media (max-width: 480px)': {
        fontSize: "25px"
    },
    '@media (max-width: 424px)': {
        fontSize: "25px"
    },
    '@media (max-width: 370px)': {
        fontSize: "20px"
    },
})
const PasswordMatcherror = styled(Typography)({
    fontSize: "12px",
    color: "#DC2626",
    display: "flex",
    alignItems: "center",
    marginTop: "-6px"
});

// Customizable Area End

export default class UserProfileDriver extends UserProfileDriverController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    documentUpload = () => {
        return (
            <>
                <FormControl fullWidth>
                    <MyFormLabel>{configJSON.dvmdocument}</MyFormLabel>
                    <Input fullWidth variant="outlined"
                        name={"dmvdocument"}
                        value={this.state.documentData.dmvdocument}
                    />
                    <input
                        name={"dmvdocument"}
                        type="file"
                        multiple
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                        }}
                        onChange={this.handlechange}
                        data-test-id="dvmdocumentfileid"
                    />
                    <img src={uploadimage.default} style={webstyle.uploadimage} />
                    {this.state.documentData.dmvdocumentError && <PasswordMatcherror>
                        {this.state.documentData.dmvdocumentError}
                    </PasswordMatcherror>}
                </FormControl>

                <FormControl fullWidth >
                    <MyFormLabel>{configJSON.insaurencedoucment}</MyFormLabel>
                    <Input fullWidth variant="outlined"
                        name={"insaurenceDocument"}
                        value={this.state.documentData.insaurenceDocument}
                    />
                    <input
                        type="file"
                        name={"insaurenceDocument"}
                        multiple
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                        }}
                        onChange={this.handlechange}
                    />
                    <Autoinsaureneuploadimage src={uploadimage.default} />
                    {this.state.documentData.insaurenceDocumentError && <PasswordMatcherror>
                        {this.state.documentData.insaurenceDocumentError}
                    </PasswordMatcherror>}
                </FormControl>

                <FormControl fullWidth >
                    <MyFormLabel>{configJSON.drivinglicencedocs}</MyFormLabel>
                    <Input fullWidth variant="outlined"
                        name={"licencePicture"}
                        value={this.state.documentData.licencePicture}
                    />
                    <input
                        type="file"
                        name={"licencePicture"}
                        multiple
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer',
                        }}
                        onChange={this.handlechange}
                    />
                    <Drivinglicenseuploadimage src={uploadimage.default} />
                    {this.state.documentData.licencePictureError && <PasswordMatcherror>
                        {this.state.documentData.licencePictureError}
                    </PasswordMatcherror>}
                </FormControl></>
        )
    }

    carDetails = () => {
        return (
            <>
                {this.state.drivereditstep == 3 && <FormContainer>
                    <FormControl fullWidth onClick={() => this.setState({ vehicletypemodal: true })} data-test-id="vehicletypemodalopen">
                        <MyFormLabel>{configJSON.vehicletext}</MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"vtype"}
                            placeholder="Vehicle type"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={this.state.carDetailsdata.vtype}
                            onChange={this.handlechangecard}
                        />
                        <img src={arrowdown.default} style={webstyle.arrowdown} />
                        {this.state.carDetailsdata.vtypeError && <PasswordMatcherror>
                            {this.state.carDetailsdata.vtypeError}
                        </PasswordMatcherror>}
                    </FormControl>

                    <FormControl fullWidth >
                        <MyFormLabel>{configJSON.vehiclemake}</MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"VehicleMake"}
                            placeholder="Vehicle make"
                            value={this.state.carDetailsdata.VehicleMake}
                            onChange={this.handlechangecard}
                        />
                        {this.state.carDetailsdata.VehicleMakeError && <PasswordMatcherror>
                            {this.state.carDetailsdata.VehicleMakeError}
                        </PasswordMatcherror>}
                    </FormControl>

                    <FormControl fullWidth >
                        <MyFormLabel>{configJSON.vehiclemodal}
                        </MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"vehiclemodel"}
                            placeholder="Vehicle model"
                            value={this.state.carDetailsdata.vehiclemodel}
                            onChange={this.handlechangecard}
                        />
                        {this.state.carDetailsdata.vehiclemodelError && <PasswordMatcherror>
                            {this.state.carDetailsdata.vehiclemodelError}
                        </PasswordMatcherror>}
                    </FormControl>

                    <FormControl fullWidth >
                        <MyFormLabel>{configJSON.year}
                        </MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"vehicleyear"}
                            placeholder="Vehicle year"
                            data-test-id="vehicleyearnumberinput"
                            value={this.state.carDetailsdata.vehicleyear}
                            onChange={this.handlechangecard}
                        />
                        {this.state.carDetailsdata.vehicleyearError && <PasswordMatcherror>
                            {this.state.carDetailsdata.vehicleyearError}
                        </PasswordMatcherror>}
                    </FormControl>

                    <FormControl fullWidth >
                        <MyFormLabel>{configJSON.seatsavailable}</MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"numberofSeats"}
                            placeholder="Number of seats"
                            data-test-id="seatnumberavailableforcar"
                            value={this.state.carDetailsdata.numberofSeats}
                            onChange={this.handlechangecard}
                        />
                        {this.state.carDetailsdata.numberofSeatsError && <PasswordMatcherror>
                            {this.state.carDetailsdata.numberofSeatsError}
                        </PasswordMatcherror>}
                    </FormControl>

                    <FormControl fullWidth  >
                        <MyFormLabel>
                            {configJSON.vehicleplate}
                        </MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"VehicleLicenceplate"}
                            placeholder="Vehicle licence plate"
                            value={this.state.carDetailsdata.VehicleLicenceplate}
                            onChange={this.handlechangecard}
                        />
                        {this.state.carDetailsdata.VehicleLicenceplateError && <PasswordMatcherror>
                            {this.state.carDetailsdata.VehicleLicenceplateError}
                        </PasswordMatcherror>}
                    </FormControl>
                </FormContainer>}
            </>
        )
    }

    documentDetails = () => {
        return (
            <DocumentUpdatecontainer>
                <input
                    type="file"
                    ref={this.fileInputRef}
                    style={{ display: "none" }}
                    onChange={this.props.handleFileChange}
                />
                {this.state.drivereditstep == 2 && <FormContainer>
                    {/* <FormControl fullWidth >
                        <MyFormLabel>{configJSON.drivinglicence}</MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"licenceNumber"}
                            onChange={this.handlechange}
                            data-test-id='licencenumberid'
                            placeholder="Type here you Driving licence number"
                            value={this.state.documentData.licenceNumber}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        {this.state.documentData.licenceNumberError && <PasswordMatcherror>
                            {this.state.documentData.licenceNumberError}
                        </PasswordMatcherror>}
                    </FormControl> */}

                    <FormControl fullWidth >
                        <MyFormLabel>{configJSON.insaurencetext}</MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"insaurenceNumber"}
                            onChange={this.handlechange}
                            placeholder="....."
                            value={this.state.documentData.insaurenceNumber}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        {this.state.documentData.insaurenceNumberError && <PasswordMatcherror>
                            {this.state.documentData.insaurenceNumberError}
                        </PasswordMatcherror>}
                    </FormControl>
                    {this.documentUpload()}
                    <FormControl fullWidth onClick={() => this.setState({ ModalOpen: true })} data-test-id="additionalrequirement">
                        <MyFormLabel>
                            {configJSON.requirement}
                        </MyFormLabel>
                        <Input fullWidth variant="outlined"
                            name={"requirement"}
                            placeholder="e.g. wheelchair user, gurney"
                            InputProps={{
                                readOnly: true,
                            }}
                            value={this.state.documentData.requirement}
                        />
                        {this.state.documentData.requirementError && <PasswordMatcherror>
                            {this.state.documentData.requirementError}
                        </PasswordMatcherror>}
                    </FormControl>
                </FormContainer>}
                {this.carDetails()}
                <Box style={webstyle.drivereditbuttonboxcontainer}>
                    <Drivereditbuttobox >
                        <Saveeditbutton onClick={() => this.handleSubmitdata()} data-test-id="saveeditdatabutton">
                            <Button style={webstyle.savetextcolor} >Save</Button>
                        </Saveeditbutton>
                        <Nexteditbutton onClick={this.handleNextbackbutton} data-test-id="nexteditdatabutton">
                            <Button style={webstyle.nexttextcolor} >{this.state.drivereditstep == 2 ? "Next" : 'Back'}</Button>
                        </Nexteditbutton>
                    </Drivereditbuttobox>

                </Box>
            </DocumentUpdatecontainer>
        )
    }
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                {this.documentDetails()}
                <Snackbar
                    open={this.state.isToastOpen}
                    autoHideDuration={500}
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <MuiAlert severity={this.state.success ? "success" : "error"} onClose={this.handleClose}
                        sx={{ backgroundColor: this.state.success ? "#4caf50" : "red", color: '#fff', width: '100%', fontSize: '18px', fontWeight: 700 }}>
                        {this.state.success ? this.state.successMessage : this.state.errorMessage}

                    </MuiAlert>
                </Snackbar>
                <Dialog open={this.state.ModalOpen} onClose={this.handleDialogClose2} PaperProps={{
                    sx: {
                        maxWidth: 'none',
                        width: "20%",
                        borderRadius: '34.73px',
                        padding: 'none',
                        border: "1px solid #F5F5F5",
                        boxShadow: "#00000026",
                        '@media (max-width: 1600px)': {
                            width: '25%',
                        },
                        '@media (max-width: 1500px)': {
                            width: '27%',
                        },
                        '@media (max-width: 1300px)': {
                            width: '30%',
                        },
                        '@media (max-width: 1100px)': {
                            width: '35%',
                        },
                        '@media (max-width: 950px)': {
                            width: '40%',
                        },
                        '@media (max-width: 850px)': {
                            width: '45%',
                        },
                        '@media (max-width: 750px)': {
                            width: '50%',
                        },
                        '@media (max-width: 650px)': {
                            width: '55%',
                        },
                        '@media (max-width: 600px)': {
                            width: '60%',
                        },
                        '@media (max-width: 550px)': {
                            width: '65%',
                        },
                        '@media (max-width: 414px)': {
                            width: '70%',
                        }
                    },
                }} className="dialog" style={{}}>
                    <DialogContent sx={{
                        paddingTop: '20px', paddingBottom: '20px', display: "flex", flexDirection: "column", gap: "30px"
                    }}>
                        {this.state.option.map((option: any) => {
                            const isSelected = this.state.documentData.requirement === option;
                            return (<div key={option} style={{ display: 'flex', alignItems: 'center', position: "relative", gap: "30px", backgroundColor: isSelected ? '#ECFDF5' : 'white', paddingRight: '20px' }}>
                                <label> <input type="checkbox" data-test-id="additionalrquirementidselection" style={this.state.documentData.requirement === option ? { ...webstyle.checkbox, ...webstyle.checkboxChecked } : webstyle.checkbox} onChange={() => {
                                    const newOptions = option;
                                    this.setState(prevState => ({
                                        documentData: {
                                            ...prevState.documentData,
                                            requirement: newOptions
                                        }
                                    }));
                                    this.handleDialogClose2()
                                }} />
                                    {this.state.documentData.requirement == option && <CheckboxCheckmark>✓</CheckboxCheckmark>}</label>
                                <Optionlabel2>{option}</Optionlabel2>
                            </div>)

                        })}
                    </DialogContent>

                </Dialog>
                <Dialog
                    open={this.state.vehicletypemodal}
                    onClose={this.handlevehicleModalclose}
                    PaperProps={{
                        sx: {
                            maxWidth: 'none',
                            width: "20%",
                            borderRadius: '11.69px',
                            padding: 'none',
                            border: "1px solid #F5F5F5",
                            boxShadow: "#00000026",
                            '@media (max-width: 1450px)': {
                                width: '25%',
                            },
                            '@media (max-width: 1100px)': {
                                width: '30%',
                            },
                            '@media (max-width: 950px)': {
                                width: '35%',
                            },
                            '@media (max-width: 850px)': {
                                width: '40%',
                            },
                            '@media (max-width: 750px)': {
                                width: '45%',
                            },
                            '@media (max-width: 600px)': {
                                width: '60%',
                            },
                            '@media (max-width: 470px)': {
                                width: '90%',
                            },
                        },
                    }}
                    className="dialog"
                >
                    <DialogContent
                        sx={{
                            paddingTop: '20px',
                            flexDirection: "column",
                            paddingBottom: '20px',
                            display: "flex",
                        }}
                    >
                        {this.state.vehicletype.map((vehicle: any) => {
                            const isSelected = this.state.carDetailsdata.vtype === vehicle;

                            return (
                                <div
                                    key={vehicle}
                                    style={{
                                        display: 'flex',
                                        position: "relative",
                                        gap: "10px",
                                        alignItems: 'center',
                                        backgroundColor: isSelected ? '#ECFDF5' : 'white',
                                        paddingRight: '20px',
                                        paddingBottom: "15px",
                                        paddingTop: "15px",
                                    }}
                                >
                                    <label>
                                        <input
                                            type="checkbox"
                                            data-test-id="vehicletypepopupselect"
                                            style={
                                                isSelected
                                                    ? { ...webstyle.vehicletypecheckbox, ...webstyle.vehiletypecheckboxchecked }
                                                    : webstyle.vehicletypecheckbox
                                            }
                                            onChange={() => {
                                                this.setState((prevState) => ({
                                                    carDetailsdata: {
                                                        ...prevState.carDetailsdata,
                                                        vtype: vehicle,
                                                    },
                                                }));
                                                this.handlevehicleModalclose();
                                            }}
                                        />
                                        {isSelected && <CheckboxCheckmarkvehicletype>✓</CheckboxCheckmarkvehicletype>}
                                    </label>
                                    <label style={webstyle.optionlabel}>{vehicle}</label>
                                </div>
                            );
                        })}
                    </DialogContent>
                </Dialog>

                <Modal
                    open={this.props.uploadImageState}
                    onClose={this.props.uploadImageFunction}
                    aria-describedby="modal-modal-description"
                    data-testID={"closeUploadImageModalID"}
                    aria-labelledby="modal-modal-title"

                >
                    <Modalcontainer boxShadow={24}>
                        <Ulpoadbox >
                            <Uploadbutton onClick={this.handleAddPhotoClick} data-test-id="driverphotoadd">
                                <StyledImageUpload src={photoUploadIcon.default} alt="Upload" />
                                <UploadText data-testID={"uploadTextID"}>
                                    {configJSON.uploadPhoto}
                                </UploadText>
                            </Uploadbutton>
                            <Uploadbutton onClick={() => this.props.removePhoto()}>
                                <StyledImageDelete src={photoDeleteIcon.default} alt="Remove" />
                                <UploadText>
                                    {configJSON.removePhoto}
                                </UploadText>
                            </Uploadbutton>
                        </Ulpoadbox>
                    </Modalcontainer>
                </Modal>

            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


const webstyle = {

    checkbox: {
        width: "37.07px",
        height: "37.07px",
        borderRadius: "12.77px",
        border: "4.34px solid #64748B",
        appearance: "none" as "none",
        cursor: "pointer",
    },

    vehicletypecheckbox: {
        width: "35.07px",
        height: "35.07px",
        borderRadius: "8.77px",
        border: "1.46px solid #64748B",
        appearance: "none" as "none",
        cursor: "pointer",
    },

    checkboxCheckmark: {
        content: '"✓"',
        position: "absolute" as "absolute",
        top: "15px",
        left: "9px",
        fontSize: "29.21px",
        color: "red",
        cursor: "pointer",
        '@media (max-width: 500px)': {
            top: "3px"
        },
    },

    checkboxChecked: {
        backgroundColor: "#87C122",
        width: "37.07px",
        height: "37.07px",
        borderRadius: "12.77px",
        border: "0px",
        appearance: "none" as "none",
        cursor: "pointer"
    },

    vehiletypecheckboxchecked: {
        backgroundColor: "#87C122",
        width: "35.07px",
        height: "35.07px",
        borderRadius: "8.77px",
        border: "0px",
        appearance: "none" as "none",
        cursor: "pointer"
    },

    contactuscontainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px"
    },

    drivereditbuttonboxcontainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        paddingBottom: "15px",
        paddingTop: "55px"
    },

    checkboxoutline: {
        width: '35.07px',
        height: '35.07px',
        borderRadius: '8.77px',
        border: "1.46 solid #64748B"
    },

    arrowdown: {
        width: "7px",
        height: "7px",
        position: "absolute" as 'absolute',
        right: "22px",
        top: "52px"
    },

    checkboxicon: {
        width: '35.07px',
        height: '35.07px',
        borderRadius: '28.05px',
        backgroundColor: '#fff',
        color: '#87C122',
    },

    drivereditbuttonbox: {
        width: "70%",
        display: "flex",
        flexDirection: "column" as "column",
        gap: '10px',
        alignItems: "center"
    },

    uploadimage: {
        height: "20px",
        width: "20px",
        position: "absolute" as 'absolute',
        right: "9px",
        top: "44px",
    },

    optionlabel: {
        color: '#0F172A',
        borderRadius: '8px',
        fontSize: '23.38px',
        fontWeight: 400,
        fontFamily: "Inter",
        lineHeight: "35.08px"
    },

    savetextcolor: {
        color: "#ECFDF5",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
        textTransform: "capitalize" as "capitalize"
    },

    donetextcolor: {
        color: "#ECFDF5",
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Inter",
        lineHeight: "28px",
        fontSize: "20px",
        fontWeight: 400,
    },

    nexttextcolor: {
        color: "#1D6FB8",
        textTransform: "capitalize" as "capitalize",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
    },

}

// Customizable Area End
